import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Inicio from "./components/inicio/Inicio";
import SegundaPantalla from "./components/Pantalla2/SegundaPantalla";
import TerceraPantalla from "./components/Pantalla3/TerceraPantalla";
import QuieneSomos from "./components/QuienesSomos/QuieneSomos";
import Acerca from "./components/AcercaDe/AcercaDe";
import Ayuda from "./components/Ayuda/Ayuda";
import PaseoLibre from "./components/PaseoLiblre/PaseoLibre";
import PaseoSectores from "./components/PaseoSectores/PaseoSectores";
import PaseoFade from "./components/PaseoSectores/Fade";
import PaseoFie from "./components/PaseoSectores/Fie";
import MenuMuseos from "./components/Museos/Museos";
import MuseoComputacion from "./components/Museos/Computacion/Computacion";
import MuseoComunicacion from "./components/Museos/Comunicacion/Comunicacion";
import MuseoOrigami from "./components/Museos/Origami/Origami";
import JuegoOrigami from "./components/Museos/Origami/JuegoOrigami";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Inicio />} />
        <Route exact path="/paseo-museo" element={<SegundaPantalla />} />
        <Route exact path="/paseo" element={<TerceraPantalla />} />
        <Route exact path="/qienes-somos" element={<QuieneSomos />} />
        <Route exact path="/acerca-de" element={<Acerca />} />
        <Route exact path="/ayuda-en-linea" element={<Ayuda />} />
        <Route exact path="/paseo-libre" element={<PaseoLibre />} />
        <Route exact path="/paseo-sectores" element={<PaseoSectores />} />
        <Route exact path="/paseo-fade" element={<PaseoFade />} />
        <Route exact path="/paseo-fie" element={<PaseoFie />} />
        <Route exact path="/museos" element={<MenuMuseos />} />
        <Route exact path="/museo-computacion" element={<MuseoComputacion />} />
        <Route
          exact
          path="/museo-comunicacion"
          element={<MuseoComunicacion />}
        />
        <Route exact path="/museo-origami" element={<MuseoOrigami />} />
        <Route exact path="/museo-origami-juego" element={<JuegoOrigami />} />
      </Routes>
    </Router>
  );
}

export default App;
