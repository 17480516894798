import "./paseolibre.css";
import "../inicio/inicio.css";
import { ReactComponent as Cincuenta } from "../../assets/001Cincuenta.svg";
import { ReactComponent as Muvi } from "../../assets/001LogoMuvi.svg";
import { ReactComponent as LogoPoli } from "../../assets/001LogoPoli.svg";

import Libre from "../PaseoLiblre/Libre";

const PaseoLibre = () => {
  return (
    <div className="content-inicio">
      <a href="/museo-paseo" className="btn-paseo-libre button">
        <span>Ingresar</span>
      </a>
      <div className="logo-Poli">
        <LogoPoli />
      </div>
      <div className="header">
        <h1 className="title-h1">PASEO CAMPUS ESPOCH</h1>
      </div>
      <Libre />
      <div className="footer">
        <Cincuenta />
        <div className="content-regresar">
          <a href="/paseo" className="btn-regresar buttonR">
            <span>REGRESAR</span>
          </a>
        </div>
        <Muvi />
      </div>
    </div>
  );
};

export default PaseoLibre;
