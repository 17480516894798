import "./comunicacion.css";
import "../../inicio/inicio.css";
import { ReactComponent as Cincuenta } from "../../../assets/001Cincuenta.svg";
import { ReactComponent as Muvi } from "../../../assets/001LogoMuvi.svg";
import { ReactComponent as LogoPoli } from "../../../assets/001LogoPoli.svg";

import Fcomunicacion from "../Comunicacion/Fcomunicacion";

const Comunicacion = () => {
  return (
    <div className="content-inicio">
      <a href="/museo-paseo" className="btn-museos button">
        <span>Ingresar</span>
      </a>
      <div className="logo-Poli">
        <LogoPoli />
      </div>
      <div className="header">
        <h1 className="title-h1">LA HISTORIA DE LA COMUNICACIÓN</h1>
      </div>
      <Fcomunicacion />
      <div className="footer">
        <Cincuenta />
        <div className="content-regresar">
          <a href="/museos" className="btn-regresar buttonR">
            <span>REGRESAR</span>
          </a>
        </div>
        <Muvi />
      </div>
    </div>
  );
};

export default Comunicacion;
