import "./museos.css";
import "../inicio/inicio.css";
import { ReactComponent as Cincuenta } from "../../assets/001Cincuenta.svg";
import { ReactComponent as Muvi } from "../../assets/001LogoMuvi.svg";
import { ReactComponent as LogoPoli } from "../../assets/001LogoPoli.svg";

import FondoMuseos from "../Museos/FondoMuseos";
import computadora from "../../assets/iconos-museos/computadora.svg";
import comunicacion from "../../assets/iconos-museos/comunicacion.svg";
import origami from "../../assets/iconos-museos/origami.svg";
import navegacion from "../../assets/iconos-museos/navegacion.svg";
import automovil from "../../assets/iconos-museos/automovil.svg";
import tren from "../../assets/iconos-museos/tren.svg";
import avion from "../../assets/iconos-museos/avion.svg";
import riobamba from "../../assets/iconos-museos/riobamba.svg";
import espoch from "../../assets/iconos-museos/espoch.svg";

const Museos = () => {
  return (
    <div className="content-inicio">
      <div className="logo-Poli">
        <LogoPoli />
      </div>
      <div className="header">
        <h1 className="title-h1">MUSEO VIRTUAL</h1>
      </div>
      <div className="botones-museos">
        <ul id="list">
          <li className="in">
            <a href="/museo-computacion" className="content-museos">
              <img class="icon-museos" src={computadora} alt="Rowing icon" />
              <div className="text-museo">
                <p>La historia de la computación</p>
              </div>
            </a>
          </li>
          <li className="in">
            <a href="/museo-comunicacion" className="content-museos">
              <img class="icon-museos" src={comunicacion} alt="Rowing icon" />
              <div className="text-museo">
                <p>La historia de la comunicación</p>
              </div>
            </a>
          </li>
          <li className="in">
            <a href="/museo-origami" className="content-museos">
              <img class="icon-museos" src={origami} alt="Rowing icon" />
              <div className="text-museo">
                <p>La historia del origami y kirigami</p>
              </div>
            </a>
          </li>
          <li className="in">
            <a href="/paseo-medicina" className="content-museos">
              <img class="icon-museos" src={navegacion} alt="Rowing icon" />
              <div className="text-museo">
                <p>La historia de la navegación</p>
              </div>
            </a>
          </li>
          <li className="in">
            <a href="/paseo-medicina" className="content-museos">
              <img class="icon-museos" src={automovil} alt="Rowing icon" />
              <div className="text-museo">
                <p>La historia del automóvil</p>
              </div>
            </a>
          </li>
          <li className="in">
            <a href="/paseo-medicina" className="content-museos">
              <img class="icon-museos" src={tren} alt="Rowing icon" />
              <div className="text-museo">
                <p>La historia del ferrocarril</p>
              </div>
            </a>
          </li>
          <li className="in">
            <a href="/paseo-medicina" className="content-museos">
              <img class="icon-museos" src={avion} alt="Rowing icon" />
              <div className="text-museo">
                <p>La historia de la aviación</p>
              </div>
            </a>
          </li>
          <li className="in">
            <a href="/paseo-medicina" className="content-museos">
              <img class="icon-museos" src={riobamba} alt="Rowing icon" />
              <div className="text-museo">
                <p>La historia de Riobamba</p>
              </div>
            </a>
          </li>
          <li className="in">
            <a href="/paseo-medicina" className="content-museos">
              <img class="icon-museos" src={espoch} alt="Rowing icon" />
              <div className="text-museo">
                <p>La historia de la ESPOCH</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <FondoMuseos />
      <div className="footer">
        <Cincuenta />
        <div className="content-regresar">
          <a href="/paseo-museo" className="btn-regresar buttonR">
            <span>REGRESAR</span>
          </a>
        </div>
        <Muvi />
      </div>
    </div>
  );
};

export default Museos;
