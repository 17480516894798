import "./inicio.css";
import audio from "../../assets/museo.mp3";
import { ReactComponent as Cincuenta } from "../../assets/001Cincuenta.svg";
import { ReactComponent as Muvi } from "../../assets/001LogoMuvi.svg";
import { ReactComponent as LogoPoli } from "../../assets/001LogoPoli.svg";
import Principal from "../PaginaPrincipal/Principal";
const Inicio = () => {
  return (
    <div className="content-inicio">
      <a href="/paseo-museo" className="custom-btn button">
        <span>Ingresar</span>
      </a>
      <div className="logo-Poli">
        <LogoPoli />
      </div>
      <div className="header">
        <h1 className="title-h1">PASEO VIRTUAL DE LA ESPOCH</h1>
      </div>
      <Principal />
      <div className="footer">
        <Cincuenta />
        <div className="content-reproductor">
          <audio
            className="content-audio"
            controls
            autoPlay
            loop="loop"
            playsinline
            oncontextmenu="return false;"
            preload="auto"
            id="myAudio"
          >
            <source src={audio} type="audio/mp3" />
          </audio>
        </div>
        <Muvi />
      </div>
    </div>
  );
};

export default Inicio;
