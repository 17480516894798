import "./museos.css";

const FondoMuseos = () => {
  return (
    <>
      <svg viewBox="0 0 1366 768">
        <defs>
          <linearGradient
            id="Degradado_sin_nombre"
            x1="1216.5"
            y1="1563.5"
            x2="149.5"
            y2="496.5"
            gradientTransform="matrix(1, 0, 0, -1, 0, 1414)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#b2dffe" />
            <stop offset="0.12" stop-color="#add9fa" />
            <stop offset="0.28" stop-color="#9ecaed" />
            <stop offset="0.45" stop-color="#86b0d8" />
            <stop offset="0.64" stop-color="#648bbb" />
            <stop offset="0.84" stop-color="#395d96" />
            <stop offset="1" stop-color="#103173" />
          </linearGradient>
        </defs>
        <g id="Capa_2" data-name="Capa 2">
          <g id="Capa_1-2" data-name="Capa 1">
            <g id="fondo">
              <rect class="cls7-1" width="1366" height="768" />
            </g>
            <g id="edificio">
              <polygon
                class="cls7-2"
                points="656.37 487.78 672.79 485.88 665.84 557.25 650.05 555.36 656.37 487.78"
              />
              <polygon
                class="cls7-3"
                points="660.79 283.76 673.99 287.77 646.26 553.47 632.36 554.73 660.79 283.76"
              />
              <polygon
                class="cls7-4"
                points="1070.31 542.41 1070.18 542.42 1069.23 542.49 1018.92 546.22 1018.92 546.2 1017.32 495.76 1017.25 493.78 1018.36 493.8 1067.76 494.99 1068.02 495 1068.19 498.71 1068.93 513.98 1070.31 542.41"
              />
              <polygon
                class="cls7-5"
                points="1185.35 346.61 1171.98 347.99 1185.99 353.56 1185.35 346.61"
              />
              <polygon
                class="cls7-6"
                points="1366 559.47 1366 563.26 889.75 658 815.85 658 1366 559.47"
              />
              <polygon
                class="cls7-7"
                points="1366 557.57 1366 559.47 815.85 658 797.53 658 1366 557.57"
              />
              <polygon
                class="cls7-7"
                points="1366 546.2 1366 548.73 564.46 658 546.78 658 1366 546.2"
              />
              <polygon
                class="cls7-8"
                points="1366 550.62 1366 557.57 797.53 658 607.41 658 1366 550.62"
              />
              <polygon
                class="cls7-6"
                points="1366 548.73 1366 550.62 607.41 658 564.46 658 1366 548.73"
              />
              <polygon
                class="cls7-9"
                points="1366 563.26 1366 658 889.75 658 1366 563.26"
              />
              <polygon
                class="cls7-9"
                points="1366 535.42 1366 546.2 546.78 658 503.19 658 503.19 617.26 566.36 610.63 981.97 574.63 1353.37 536.73 1353.37 535.46 1366 535.42"
              />
              <polygon
                class="cls7-6"
                points="1353.37 535.46 1353.37 536.73 981.97 574.63 566.36 610.63 503.19 617.26 503.19 609.41 517.44 608.16 575.83 603.05 736.34 589.67 739.13 589.44 969.97 570.2 1226.41 546.83 1353.37 535.46"
              />
              <polygon
                class="cls7-8"
                points="706.8 560.39 706.8 568.94 624.47 582.84 615.62 582.84 520.88 572.73 613.1 562.62 664.28 558.68 664.96 558.63 665.42 558.59 665.95 558.66 687 561.36 706.8 560.39"
              />
              <polygon
                class="cls7-10"
                points="1353.37 535.46 1226.41 546.83 969.97 570.2 739.13 589.44 724.9 584.1 594.78 594.84 503.19 603.36 503.19 555.81 613.73 553.15 616.89 553.15 616.96 553.16 616.89 553.77 503.83 563.26 513.93 567.68 624.88 554.12 629.2 554.29 631.84 554.54 645.95 553.75 645.94 553.78 648.9 553.86 664.5 554.77 664.5 557.15 664.28 558.68 613.1 562.62 520.88 572.73 615.62 582.84 624.47 582.84 706.8 568.94 706.8 562.62 725.41 563.77 752.69 565.78 754.36 565.56 754.6 565.53 754.58 565.78 772.23 563.22 789.47 560.95 803.22 562.35 803.23 562.35 803.22 562.57 802.59 562.62 831.01 569.57 1019.87 553.78 1001.2 546.32 1001.26 546.31 1011.59 545.58 1011.66 545.57 1018.38 544.96 1068.69 541.23 1069.64 541.26 1121.74 543.25 1121.88 543.25 1132.3 543.67 1146.83 547.47 1252.35 537.41 1353.37 535.46"
              />
              <polyline
                class="cls7-11"
                points="664.5 557.15 664.5 558.47 664.96 558.53 665.42 558.59 665.95 558.66 686.69 561.23 687.01 561.22 695.34 560.83 706.8 560.3 706.8 560.39 706.8 562.4 725.41 563.77"
              />
              <line
                class="cls7-11"
                x1="772.23"
                y1="563.22"
                x2="754.6"
                y2="565.53"
              />
              <polyline
                class="cls7-11"
                points="1121.88 543.25 1132.3 543.65 1132.48 543.66 1132.93 543.67 1144.3 542.54 1152.19 541.75 1189.78 537.99 1191.53 537.99 1192.3 537.99 1206.11 537.99 1207.69 537.99 1219.23 537.99 1219.46 537.99 1221.99 537.99 1252.07 537.41 1252.35 537.41"
              />
              <polygon
                class="cls7-8"
                points="624.88 554.12 513.93 567.68 503.83 563.26 616.89 553.77 616.89 553.78 624.88 554.12"
              />
              <polygon
                class="cls7-12"
                points="661.1 283.44 632.05 554.41 629.2 554.29 624.88 554.12 616.89 553.78 616.89 553.77 616.96 553.16 641.46 330.18 642.78 318.18 620.05 332.08 622.57 308.71 661.1 283.44"
              />
              <polygon
                class="cls7-13"
                points="640.78 336.43 620.05 332.08 642.78 318.18 640.78 336.43"
              />
              <polygon
                class="cls7-14"
                points="641.46 330.18 616.89 553.15 613.73 553.15 639 330.18 641.46 330.18"
              />
              <polygon
                class="cls7-14"
                points="776.06 198.17 774.9 217.74 754.36 565.56 725.41 563.77 706.8 562.62 706.8 560.1 695.34 560.83 687 561.36 664.92 558.91 664.26 558.83 664.28 558.68 664.5 557.15 664.89 554.41 671.21 486.83 657.31 488.09 651.3 553.92 648.9 553.86 645.94 553.78 645.95 553.75 675.63 268.92 692.96 256.71 776.06 198.17"
              />
              <polygon
                class="cls7-15"
                points="1207.69 537.99 1190.99 538.12 1190.68 538.12 1172.51 539.87 1132.93 543.67 1132.48 543.66 1132.3 543.65 1121.88 543.25 1121.74 543.25 1069.64 541.26 1069.63 541.16 1068.62 522 1068.14 512.73 1067.24 495.67 1017.9 494.52 1016.77 494.5 1012.92 494.41 1015.35 545.17 1011.65 545.47 1011.59 545.48 1001.26 546.31 1001.2 546.32 1000.92 546.33 934.59 551.7 814.87 561.36 814.85 561.36 804.46 562.2 804.4 562.21 803.24 562.09 803.23 562.09 789.32 560.73 772.23 563.22 754.58 565.78 754.6 565.53 774.91 217.74 776.06 198.17 827.42 216.88 1003.09 280.87 1026.11 290.02 1067.03 306.28 1069.41 307.23 1103.35 320.72 1105.77 321.68 1113.5 324.75 1169.97 347.19 1185.62 353.41 1185.99 353.56 1207.67 537.84 1207.69 537.99"
              />
              <polygon
                class="cls7-16"
                points="1007.24 304.29 1059.47 321.3 1063.25 374.4 1009.13 362.4 1007.24 304.29"
              />
              <polygon
                class="cls7-16"
                points="1010.39 366.19 1062.63 377.51 1065.77 430.61 1012.29 422.4 1010.39 366.19"
              />
              <polygon
                class="cls7-16"
                points="1013.55 429.35 1065.79 434.7 1069.56 485.88 1015.45 484.8 1013.55 429.35"
              />
              <polygon
                class="cls7-17"
                points="676.34 475.79 675.63 483.67 650.36 486.83 651.17 478.92 676.34 475.79"
              />
              <polygon
                class="cls7-2"
                points="693.95 280.29 676.34 475.79 651.17 478.92 669.95 295.44 693.95 280.29"
              />
              <polygon
                class="cls7-3"
                points="712.89 252.49 767.85 215.23 774.25 229.14 731.21 256.92 712.89 252.49"
              />
              <polygon
                class="cls7-12"
                points="815.85 232.28 803.22 237.97 774.16 229.12 767.85 215.23 815.85 232.28"
              />
              <polygon
                class="cls7-3"
                points="712.89 252.49 687 561.36 706.8 560.1 731.21 256.92 712.89 252.49"
              />
              <polygon
                class="cls7-12"
                points="803.22 237.97 815.85 232.28 803.22 562.35 789.32 560.73 803.22 237.97"
              />
              <polygon
                class="cls7-17"
                points="827.85 205.12 827.42 216.88 814.88 560.94 814.87 561.36 814.85 561.36 805.26 562.4 803.22 562.62 803.22 562.57 803.23 562.35 803.24 562.09 815.85 232.28 774.91 217.74 774.9 217.74 767.85 215.23 712.89 252.49 687.01 561.22 687 561.36 664.91 559.07 664.92 558.91 664.96 558.63 664.96 558.53 692.96 256.71 694.58 239.23 769.74 183.65 827.85 205.12"
              />
              <polygon
                class="cls7-18"
                points="1132.3 543.65 1132.3 543.67 1121.88 543.25 1121.74 543.25 1069.77 541.15 1068.62 522 1068.07 512.73 1068.14 512.73 1068.36 512.72 1068.39 512.72 1077.5 512.61 1078.38 512.6 1094.44 512.41 1095.11 512.4 1112.32 512.2 1112.94 512.2 1121.56 512.09 1120.93 495.67 1112.62 495.95 1111.94 495.97 1095.03 496.54 1094.44 496.56 1077.71 497.11 1077.12 497.13 1067.65 497.45 1067.47 497.45 1067.4 497.45 1067.21 493.73 1067.01 489.46 1065.5 458.39 1065.49 458.2 1065.34 455.25 1065.36 455.25 1117.14 450.19 1116.51 433.14 1064.71 439.46 1062.19 399.66 1062.39 399.63 1110.82 391.45 1110.82 375.03 1061.56 385.14 1059.03 345.98 1105.77 334.61 1105.77 320.08 1103.35 320.72 1058.06 332.63 1057.77 332.71 1056.84 315.61 1056.5 309.34 1067.03 306.28 1069.28 305.63 1110.82 293.55 1113.5 324.75 1132.3 543.65"
              />
              <polygon
                class="cls7-19"
                points="1117.14 450.19 1065.36 455.25 1064.71 439.46 1116.51 433.14 1117.14 450.19"
              />
              <polygon
                class="cls7-20"
                points="1182.83 387.66 1167.04 385.77 1165.77 366.19 1181.56 369.35 1182.83 387.66"
              />
              <polygon
                class="cls7-20"
                points="1187.88 431.25 1171.46 428.72 1170.19 409.14 1185.99 411.67 1187.88 431.25"
              />
              <polygon
                class="cls7-20"
                points="1192.93 476.09 1177.14 475.46 1175.88 454.62 1191.67 455.88 1192.93 476.09"
              />
              <polygon
                class="cls7-20"
                points="1197.99 522.2 1181.56 522.2 1180.3 501.36 1196.72 501.36 1197.99 522.2"
              />
              <polygon
                class="cls7-19"
                points="1110.82 375.03 1110.82 391.45 1062.39 399.63 1061.56 385.14 1110.82 375.03"
              />
              <polygon
                class="cls7-19"
                points="1105.77 320.08 1105.77 334.61 1058.93 345.85 1058.06 332.63 1105.77 320.08"
              />
              <polygon
                class="cls7-19"
                points="1121.56 512.09 1068.36 512.72 1067.47 497.45 1120.93 495.67 1121.56 512.09"
              />
              <polygon
                class="cls7-3"
                points="1200.51 363.66 1201.14 371.87 1213.78 369.35 1200.51 363.66"
              />
              <polygon
                class="cls7-17"
                points="1195.46 343.45 1212.51 350.4 1214.41 369.35 1201.23 363.97 1221.99 537.99 1219.24 537.99 1195.46 343.45"
              />
              <polygon
                class="cls7-12"
                points="1184.72 345.34 1195.46 343.45 1219.46 537.99 1206.11 537.99 1184.72 345.34"
              />
              <polygon
                class="cls7-15"
                points="1007.24 359.87 1060.74 372.46 1061.35 381.98 1007.24 369.98 1007.24 359.87"
              />
              <path
                class="cls7-15"
                d="M1010.39,418.61l53.54,8.52s.59,10.61.69,10.83-54.23-6.08-54.23-6.08Z"
              />
              <polygon
                class="cls7-15"
                points="1014.18 481.14 1066.72 483.62 1067.24 495.67 1014.18 494.44 1014.18 481.14"
              />
              <rect
                class="cls7-21"
                x="1015.94"
                y="309"
                width="1.89"
                height="185.78"
                transform="translate(-18.72 51.06) rotate(-2.85)"
              />
              <rect
                class="cls7-22"
                x="1031.77"
                y="312.44"
                width="1.89"
                height="182.33"
                transform="translate(-19.16 52.95) rotate(-2.91)"
              />
              <rect
                class="cls7-23"
                x="1046.91"
                y="318.82"
                width="1.9"
                height="176.65"
                transform="translate(-19.87 55.4) rotate(-3)"
              />
              <rect
                class="cls7-24"
                x="1033.61"
                y="319.73"
                width="1.9"
                height="51.23"
                transform="translate(444.22 1262.45) rotate(-75.69)"
              />
              <rect
                class="cls7-25"
                x="1035.53"
                y="375.5"
                width="1.89"
                height="53.22"
                transform="translate(446.61 1344.46) rotate(-79.16)"
              />
              <rect
                class="cls7-26"
                x="1038.99"
                y="431.95"
                width="1.89"
                height="51.49"
                transform="translate(511.52 1463.54) rotate(-86.04)"
              />
              <polygon
                class="cls7-27"
                points="1070.4 320.08 1007.24 299.24 1007.24 308.08 1063.45 325.76 1070.4 320.08"
              />
              <polygon
                class="cls7-18"
                points="1056.84 315.61 1009.4 299.95 1017.81 492.54 1017.9 494.52 1020.1 544.8 1018.38 544.96 1011.66 545.57 1011.59 545.58 1011.59 545.48 1011.59 545.46 1000.92 280.92 1026.11 290.02 1069.28 305.63 1069.41 307.23 1070.4 320.08 1056.84 315.61"
              />
              <polygon
                class="cls7-28"
                points="1011.66 545.57 1011.59 545.58 1001.26 546.31 1001.2 546.32 1000.92 546.33 1000.92 546.3 1000.91 546.2 990.81 284.71 1000.92 280.92 1011.65 545.46 1011.65 545.47 1011.66 545.57"
              />
              <rect
                class="cls7-29"
                x="574.76"
                y="382.96"
                width="189.73"
                height="1.26"
                transform="translate(216.29 1008.11) rotate(-83.83)"
              />
              <rect
                class="cls7-30"
                x="580.84"
                y="380.17"
                width="193.15"
                height="1.26"
                transform="translate(231 1016.89) rotate(-84.28)"
              />
              <rect
                class="cls7-31"
                x="666.31"
                y="311.87"
                width="23.42"
                height="1.26"
                transform="translate(-66.81 363.47) rotate(-28.66)"
              />
              <rect
                class="cls7-32"
                x="663.83"
                y="335.12"
                width="23.73"
                height="1.26"
                transform="translate(-78.43 360.07) rotate(-28.27)"
              />
              <rect
                class="cls7-33"
                x="663.38"
                y="345.32"
                width="22.66"
                height="1.26"
                transform="translate(-81.99 297.87) rotate(-23.51)"
              />
              <rect
                class="cls7-34"
                x="660.63"
                y="371.92"
                width="22.97"
                height="1.26"
                transform="translate(-92.87 299.57) rotate(-23.55)"
              />
              <rect
                class="cls7-35"
                x="658.97"
                y="398.54"
                width="21.62"
                height="1.26"
                transform="translate(-91.2 229.3) rotate(-18.21)"
              />
              <rect
                class="cls7-36"
                x="657.44"
                y="409.41"
                width="22.06"
                height="1.26"
                transform="translate(-89.5 208.86) rotate(-16.66)"
              />
              <rect
                class="cls7-37"
                x="654.89"
                y="437.52"
                width="21.67"
                height="1.26"
                transform="translate(-75.22 144.62) rotate(-11.74)"
              />
              <rect
                class="cls7-38"
                x="652.7"
                y="465.09"
                width="21.56"
                height="1.26"
                transform="translate(-58.67 97.49) rotate(-8.05)"
              />
              <polygon
                class="cls7-39"
                points="898.75 288.02 864.17 300.81 898.75 310.76 898.75 288.02"
              />
              <polygon
                class="cls7-40"
                points="904.91 312.5 898.75 310.76 898.75 288.02 904.91 285.97 904.91 312.5"
              />
              <polygon
                class="cls7-39"
                points="975.65 307.45 975.46 307.5 932.39 319.45 975.65 331.45 975.65 307.45"
              />
              <polygon
                class="cls7-39"
                points="898.75 353.71 845.54 368.71 898.75 378.35 898.75 353.71"
              />
              <polygon
                class="cls7-40"
                points="904.91 379.45 898.75 378.35 898.75 353.71 904.91 352.29 904.91 379.45"
              />
              <polygon
                class="cls7-39"
                points="977.55 368.08 977.35 368.14 918.81 382.61 977.55 392.72 977.55 368.08"
              />
              <polygon
                class="cls7-39"
                points="898.75 421.93 830.69 433.79 830.69 445.77 898.75 451.62 898.75 421.93"
              />
              <polygon
                class="cls7-40"
                points="904.91 452.09 898.75 451.62 898.75 421.93 904.91 421.77 904.91 452.09"
              />
              <polygon
                class="cls7-39"
                points="979.44 431.88 979.25 431.93 914.11 442.33 914.38 452.72 979.44 457.77 979.44 431.88"
              />
              <polygon
                class="cls7-39"
                points="896.86 495.83 829.42 494.43 828.16 528.52 896.86 528.04 896.86 495.83"
              />
              <polygon
                class="cls7-40"
                points="905.54 527.88 896.86 528.04 896.86 495.83 905.54 495.67 905.54 527.88"
              />
              <polygon
                class="cls7-39"
                points="981.34 498.83 981.14 498.88 914.11 497.29 914.38 527.88 981.34 526.62 981.34 498.83"
              />
              <polygon
                class="cls7-5"
                points="914.38 289.13 914.38 315.02 904.91 312.5 904.91 285.97 914.38 289.13"
              />
              <polygon
                class="cls7-5"
                points="914.38 354.19 914.38 381.35 904.91 379.45 904.91 352.29 914.38 354.19"
              />
              <polygon
                class="cls7-5"
                points="914.38 423.67 914.38 452.72 904.91 452.09 904.91 423.03 914.38 423.67"
              />
              <rect
                class="cls7-5"
                x="904.91"
                y="496.3"
                width="9.47"
                height="31.58"
              />
              <polygon
                class="cls7-41"
                points="836.06 263.23 834.8 292.92 975.65 331.45 975.65 306.81 836.06 263.23"
              />
              <polygon
                class="cls7-41"
                points="834.17 335.24 832.9 366.82 977.55 392.72 977.55 366.82 834.17 335.24"
              />
              <polygon
                class="cls7-41"
                points="831.01 411.67 830.38 445.77 979.44 457.77 979.44 430.61 831.01 411.67"
              />
              <polygon
                class="cls7-41"
                points="828.48 493.14 827.85 528.52 981.34 526.62 981.34 497.57 828.48 493.14"
              />
              <rect
                class="cls7-42"
                x="830.3"
                y="280.26"
                width="29.5"
                height="1.26"
                transform="translate(545.65 1119.56) rotate(-88.74)"
              />
              <rect
                class="cls7-43"
                x="859.05"
                y="288.52"
                width="28.41"
                height="1.26"
                transform="translate(563.77 1155.4) rotate(-88.66)"
              />
              <rect
                class="cls7-44"
                x="898.88"
                y="283.04"
                width="1.26"
                height="27.58"
              />
              <rect
                class="cls7-44"
                x="923.56"
                y="290.75"
                width="1.26"
                height="26.62"
              />
              <rect
                class="cls7-44"
                x="946.69"
                y="297.97"
                width="1.26"
                height="25.62"
              />
              <rect
                class="cls7-44"
                x="968.22"
                y="304.69"
                width="1.26"
                height="24.87"
              />
              <rect
                class="cls7-45"
                x="904.73"
                y="233.85"
                width="1.26"
                height="146.22"
                transform="translate(361.57 1093.21) rotate(-74.06)"
              />
              <rect
                class="cls7-46"
                x="904.64"
                y="237.14"
                width="1.26"
                height="146.1"
                transform="translate(365.68 1100.49) rotate(-74.59)"
              />
              <rect
                class="cls7-47"
                x="827.21"
                y="352.15"
                width="31.22"
                height="1.26"
                transform="translate(482.26 1192.3) rotate(-89.47)"
              />
              <rect
                class="cls7-44"
                x="871.51"
                y="343.6"
                width="1.26"
                height="30.24"
              />
              <rect
                class="cls7-48"
                x="898.87"
                y="349.69"
                width="1.26"
                height="28.87"
                transform="translate(-0.38 0.94) rotate(-0.06)"
              />
              <rect
                class="cls7-44"
                x="923.56"
                y="355.07"
                width="1.26"
                height="28.1"
              />
              <rect
                class="cls7-44"
                x="947.55"
                y="360.35"
                width="1.26"
                height="27.31"
              />
              <rect
                class="cls7-44"
                x="970.09"
                y="365.31"
                width="1.26"
                height="26.18"
              />
              <rect
                class="cls7-49"
                x="904.78"
                y="299.79"
                width="1.26"
                height="146.96"
                transform="translate(368.81 1192.52) rotate(-79.18)"
              />
              <rect
                class="cls7-50"
                x="904.6"
                y="303.29"
                width="1.26"
                height="147.03"
                transform="translate(368.77 1197.56) rotate(-79.43)"
              />
              <rect
                class="cls7-51"
                x="823.75"
                y="429.1"
                width="33.61"
                height="1.26"
                transform="translate(383.2 1255.57) rotate(-88.1)"
              />
              <rect
                class="cls7-52"
                x="854.94"
                y="432.4"
                width="32.25"
                height="1.26"
                transform="translate(415.23 1292.38) rotate(-88.49)"
              />
              <rect
                class="cls7-53"
                x="883.78"
                y="435.36"
                width="31.21"
                height="1.26"
                transform="translate(459.94 1333.69) rotate(-89.78)"
              />
              <rect
                class="cls7-44"
                x="924.19"
                y="423.56"
                width="1.26"
                height="29.77"
              />
              <rect
                class="cls7-44"
                x="949.1"
                y="426.82"
                width="1.26"
                height="28.65"
              />
              <rect
                class="cls7-54"
                x="971.78"
                y="429.72"
                width="1.26"
                height="27.87"
                transform="translate(-9.82 22.18) rotate(-1.3)"
              />
              <rect
                class="cls7-55"
                x="904.49"
                y="370.65"
                width="1.26"
                height="149.37"
                transform="translate(379.28 1306.19) rotate(-84.78)"
              />
              <rect
                class="cls7-56"
                x="908.55"
                y="371.72"
                width="1.27"
                height="149.93"
                transform="translate(382.8 1312.17) rotate(-84.86)"
              />
              <rect
                class="cls7-57"
                x="821.24"
                y="510.24"
                width="34.76"
                height="1.26"
                transform="translate(314.64 1341.36) rotate(-89.1)"
              />
              <rect
                class="cls7-44"
                x="869.04"
                y="494.43"
                width="1.26"
                height="33.57"
              />
              <rect
                class="cls7-44"
                x="898.28"
                y="495.18"
                width="1.26"
                height="32.46"
              />
              <rect
                class="cls7-44"
                x="924.99"
                y="495.95"
                width="1.26"
                height="31.35"
              />
              <path
                class="cls7-44"
                d="M951.33,527h-1.26V496.69h.63v-.63c.62,0,.62.59.62,1V527Z"
              />
              <rect
                class="cls7-44"
                x="973.62"
                y="497.34"
                width="1.26"
                height="29.37"
              />
              <rect
                class="cls7-58"
                x="828.14"
                y="519.47"
                width="152.87"
                height="1.26"
                transform="translate(-4.77 8.39) rotate(-0.53)"
              />
              <rect
                class="cls7-59"
                x="828.31"
                y="523.69"
                width="152.73"
                height="1.26"
                transform="translate(-4.09 7.12) rotate(-0.45)"
              />
              <rect
                class="cls7-44"
                x="1066.03"
                y="330.37"
                width="1.26"
                height="13.62"
              />
              <rect
                class="cls7-60"
                x="1081.28"
                y="326.43"
                width="1.26"
                height="13.82"
                transform="translate(-0.87 2.83) rotate(-0.15)"
              />
              <rect
                class="cls7-61"
                x="1097.32"
                y="322.17"
                width="1.26"
                height="14.08"
                transform="translate(-10.87 38.71) rotate(-2.01)"
              />
              <rect
                class="cls7-62"
                x="1069.4"
                y="383.55"
                width="1.26"
                height="14.83"
                transform="translate(-17.17 50.65) rotate(-2.69)"
              />
              <rect
                class="cls7-63"
                x="1085.87"
                y="380.1"
                width="1.26"
                height="15.43"
                transform="translate(-14.75 44.11) rotate(-2.31)"
              />
              <rect
                class="cls7-64"
                x="1102.83"
                y="376.55"
                width="1.26"
                height="16"
                transform="translate(-21.2 67.3) rotate(-3.46)"
              />
              <rect
                class="cls7-65"
                x="1073.29"
                y="438.46"
                width="1.26"
                height="16.01"
                transform="translate(-22.37 58.16) rotate(-3.07)"
              />
              <rect
                class="cls7-66"
                x="1089.85"
                y="436.37"
                width="1.26"
                height="16.33"
                transform="translate(-23.65 63.12) rotate(-3.28)"
              />
              <rect
                class="cls7-67"
                x="1107.11"
                y="434.28"
                width="1.26"
                height="16.82"
                transform="translate(-23.78 64.89) rotate(-3.32)"
              />
              <rect
                class="cls7-44"
                x="1077.12"
                y="497.11"
                width="1.26"
                height="15.5"
              />
              <rect
                class="cls7-68"
                x="1093.75"
                y="499.95"
                width="1.26"
                height="15.87"
                transform="translate(-19.42 44.15) rotate(-2.29)"
              />
              <rect
                class="cls7-69"
                x="1111.87"
                y="495.88"
                width="1.26"
                height="16.25"
                transform="translate(-27.93 66.87) rotate(-3.4)"
              />
              <rect
                class="cls7-70"
                x="1181.39"
                y="364.94"
                width="0.63"
                height="18.84"
                transform="translate(-23.26 83.43) rotate(-4)"
              />
              <rect
                class="cls7-71"
                x="1177.11"
                y="378.05"
                width="0.63"
                height="10.57"
                transform="translate(644.47 1501.03) rotate(-82.54)"
              />
              <rect
                class="cls7-72"
                x="1177.14"
                y="379.74"
                width="0.63"
                height="10.81"
                transform="translate(657.37 1509.53) rotate(-83.29)"
              />
              <rect
                class="cls7-73"
                x="1182.79"
                y="410.98"
                width="0.63"
                height="19.65"
                transform="translate(-32.23 104.93) rotate(-5.01)"
              />
              <rect
                class="cls7-74"
                x="1182.04"
                y="420.62"
                width="0.63"
                height="10.44"
                transform="translate(565.68 1520.83) rotate(-80.42)"
              />
              <rect
                class="cls7-75"
                x="1181.89"
                y="423.27"
                width="0.63"
                height="10.28"
                transform="translate(543.26 1511.41) rotate(-79.38)"
              />
              <rect
                class="cls7-76"
                x="1187.86"
                y="455.4"
                width="0.63"
                height="20.48"
                transform="translate(-28.38 80.19) rotate(-3.82)"
              />
              <rect
                class="cls7-77"
                x="1182.86"
                y="470.61"
                width="9.79"
                height="0.63"
                transform="translate(-2.77 7.06) rotate(-0.34)"
              />
              <rect
                class="cls7-77"
                x="1182.87"
                y="473.13"
                width="9.95"
                height="0.63"
                transform="translate(-2.79 7.06) rotate(-0.34)"
              />
              <rect
                class="cls7-70"
                x="1195.69"
                y="501.47"
                width="0.63"
                height="20.94"
                transform="translate(-32.83 84.77) rotate(-4)"
              />
              <rect
                class="cls7-78"
                x="1192.44"
                y="511.69"
                width="0.63"
                height="9.77"
                transform="translate(660.2 1702.28) rotate(-89.23)"
              />
              <rect
                class="cls7-79"
                x="1192.47"
                y="514.2"
                width="0.63"
                height="9.94"
                transform="translate(636.4 1695.06) rotate(-88.2)"
              />
              <polygon
                class="cls7-80"
                points="1110.82 293.55 1166.4 316.29 1191.99 537.95 1132.3 543.65 1110.82 293.55"
              />
              <polygon
                class="cls7-8"
                points="739.13 589.44 736.34 589.67 517.44 608.16 503.19 609.37 503.19 603.36 594.78 594.84 724.9 584.1 739.13 589.44"
              />
              <polygon
                class="cls7-8"
                points="1252.35 537.41 1146.83 547.47 1132.3 543.67 1132.48 543.66 1144.3 542.54 1172.51 539.87 1190.99 538.12 1192.3 537.99 1206.1 537.86 1207.67 537.84 1219.2 537.73 1219.43 537.73 1221.96 537.71 1252.07 537.41 1252.35 537.41"
              />
              <polygon
                class="cls7-8"
                points="1019.87 553.78 831.01 569.57 802.59 562.62 803.22 562.57 805.26 562.4 934.59 551.7 977.6 548.13 1000.91 546.2 1000.92 546.2 1001.13 546.28 1001.2 546.32 1019.87 553.78"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default FondoMuseos;
