import "./sectores.css";
import "../inicio/inicio.css";

import { ReactComponent as Cincuenta } from "../../assets/001Cincuenta.svg";
import { ReactComponent as Muvi } from "../../assets/001LogoMuvi.svg";

const Fade = () => {
  return (
    <div className="content-inicio">
      <div data-height="600" data-width="960" class="game_frame game_loaded">
        <iframe
          mozallowfullscreen="true"
          allow="autoplay; fullscreen; geolocation; microphone; camera; midi"
          src="Paseos/Fade/index.html"
          msallowfullscreen="true"
          scrolling="no"
          allowfullscreen="true"
          webkitallowfullscreen="true"
          allowtransparency="true"
          frameborder="0"
          style={{ width: "100%", height: "70vh" }}
        ></iframe>
      </div>
      <div className="footer">
        <Cincuenta />
        <div className="content-regresar">
          <a href="/paseo-sectores" className="btn-regresar buttonR">
            <span>REGRESAR</span>
          </a>
        </div>
        <Muvi />
      </div>
    </div>
  );
};

export default Fade;
