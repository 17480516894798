import "./sectores.css";
import "../inicio/inicio.css";
import { ReactComponent as Cincuenta } from "../../assets/001Cincuenta.svg";
import { ReactComponent as Muvi } from "../../assets/001LogoMuvi.svg";
import { ReactComponent as LogoPoli } from "../../assets/001LogoPoli.svg";

import Sectores from "../PaseoSectores/Sectores";

const PaseoSectores = () => {
  return (
    <div className="content-inicio">
      <div className="logo-Poli">
        <LogoPoli />
      </div>
      <div className="header">
        <h1 className="title-h1">ZONAS PASEO CAMPUS ESPOCH</h1>
      </div>
      <div className="botones-sectores">
        <ul id="list">
          <li className="in">
            <a href="/paseo-medicina" className="medicina"></a>
          </li>
          <li className="in">
            <a href="/paseo-fie" className="fie"></a>
          </li>
          <li className="in">
            <a href="/paseo-edificio-central" className="central"></a>
          </li>
          <li className="in">
            <a href="/paseo-recursos-naturales1" className="recursos1"></a>
          </li>
          <li className="in">
            <a href="/paseo-recursos-naturales2" className="recursos2"></a>
          </li>
          <li className="in">
            <a href="/paseo-laboratorio-hospital" className="laboratorio"></a>
          </li>
          <li className="in">
            <a href="/paseo-ciencias-pecuarias" className="pecuarias"></a>
          </li>
          <li className="in">
            <a href="/paseo-ciencias" className="ciencias"></a>
          </li>
          <li className="in">
            <a href="/paseo-dtics-electronica" className="dtics"></a>
          </li>
          <li className="in">
            <a href="/paseo-mecanica-finanzas" className="mecanica"></a>
          </li>
          <li className="in">
            <a href="/paseo-auditoria" className="auditoria"></a>
          </li>
          <li className="in">
            <a href="/paseo-fade" className="fade"></a>
          </li>
        </ul>
      </div>
      <Sectores />
      <div className="footer">
        <Cincuenta />
        <div className="content-regresar">
          <a href="/paseo" className="btn-regresar buttonR">
            <span>REGRESAR</span>
          </a>
        </div>
        <Muvi />
      </div>
    </div>
  );
};

export default PaseoSectores;
