import "./pantalla2.css";
import "../inicio/inicio.css";
import { ReactComponent as Cincuenta } from "../../assets/001Cincuenta.svg";
import { ReactComponent as Muvi } from "../../assets/001LogoMuvi.svg";
import { ReactComponent as LogoPoli } from "../../assets/001LogoPoli.svg";
import Dos from "../MuseoPaseo/MuseoPaseo";

const SegundaPantalla = () => {
  return (
    <div className="content-inicio">
      <a href="/paseo" className="btn-museo-virtual button">
        <span>PASEO VIRTUAL</span>
      </a>
      <a href="/museos" className="btn-paseo-virtual button">
        <span>MUSEO VIRTUAL</span>
      </a>
      <div className="logo-Poli">
        <LogoPoli />
      </div>
      <div className="header">
        <h1 className="title-h1">PASEO VIRTUAL DE LA ESPOCH</h1>
      </div>
      <Dos />
      <div className="footer">
        <Cincuenta />
        <div className="content-regresar">
          <a href="/" className="btn-regresar buttonR">
            <span>REGRESAR</span>
          </a>
        </div>
        <Muvi />
      </div>
    </div>
  );
};

export default SegundaPantalla;
