import "./origami.css";
import "../../inicio/inicio.css";
import { ReactComponent as Cincuenta } from "../../../assets/001Cincuenta.svg";
import { ReactComponent as Muvi } from "../../../assets/001LogoMuvi.svg";
import { ReactComponent as LogoPoli } from "../../../assets/001LogoPoli.svg";

import Forigami from "../Origami/Forigami";

const Origami = () => {
  return (
    <div className="content-inicio">
      <a href="/museo-origami-juego" className="btn-museos button">
        <span>Ingresar</span>
      </a>
      <div className="logo-Poli">
        <LogoPoli />
      </div>
      <div className="header">
        <h1 className="title-h1">LA HISTORIA DEL ORIGAMI Y KIRIGAMI</h1>
      </div>
      <Forigami />
      <div className="footer">
        <Cincuenta />
        <div className="content-regresar">
          <a href="/museos" className="btn-regresar buttonR">
            <span>REGRESAR</span>
          </a>
        </div>
        <Muvi />
      </div>
    </div>
  );
};

export default Origami;
