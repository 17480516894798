import "./ayuda.css";
import "../inicio/inicio.css";
import { ReactComponent as Cincuenta } from "../../assets/001Cincuenta.svg";
import { ReactComponent as Muvi } from "../../assets/001LogoMuvi.svg";
import { ReactComponent as LogoPoli } from "../../assets/001LogoPoli.svg";

import teclado from "../../assets/ayuda/movimiento.svg";
import raton from "../../assets/ayuda/maus.svg";
import codigoqr from "../../assets/ayuda/codigoqr.svg";

const Ayuda = () => {
  return (
    <div className="content-inicio">
      <div className="logo-Poli">
        <LogoPoli />
      </div>
      <div className="header">
        <h1 className="title-h1">AYUDA EN LÍNEA</h1>

        <div className="content-text">
          <p className="title-ayuda">
            Proyecto realizado por el homenage a los 50 años de la Escuela
            Superior Politécnica de Chimborazo
          </p>
        </div>

        <div className="body-ayuda">
          <div className="ayuda-controles">
            <h2>MODO PASEO VIRTUAL</h2>
            <h3>CONTROLES AVATAR</h3>
            <div className="contenedor-controles">
              <div className="teclado">
                <p>Desplazamiento</p>
                <div className="contenedor-iconos">
                  <img class="icon-teclado" src={teclado} alt="Rowing icon" />
                </div>
              </div>
              <div className="raton">
                <p>Vista / Acción</p>
                <div className="contenedor-iconos">
                  <img class="icon-maus" src={raton} alt="Rowing icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="ayuda-blog">
            <a
              href="https://blog-origami.vercel.app/"
              className="btn-blog button"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>BLOG</span>
            </a>
            <img class="icon-qr" src={codigoqr} alt="Rowing icon" />
          </div>
        </div>
      </div>

      <div className="footer">
        <Cincuenta />
        <div className="content-regresar">
          <a href="/paseo" className="btn-regresar buttonR">
            <span>REGRESAR</span>
          </a>
        </div>
        <Muvi />
      </div>
    </div>
  );
};

export default Ayuda;
