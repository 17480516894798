import "./quienesomos.css";
import "../inicio/inicio.css";
import { ReactComponent as Cincuenta } from "../../assets/001Cincuenta.svg";
import { ReactComponent as Muvi } from "../../assets/001LogoMuvi.svg";
import { ReactComponent as LogoPoli } from "../../assets/001LogoPoli.svg";

import decano from "../../assets/avatares/Decano.svg";
import coordinador from "../../assets/avatares/Coordinador.svg";
import unity from "../../assets/avatares/Cristian.svg";
import web from "../../assets/avatares/Ruben.svg";
import dise1 from "../../assets/avatares/Freddy.svg";
import dise2 from "../../assets/avatares/Edison.svg";

const QieneSomos = () => {
  return (
    <div className="content-inicio">
      <div className="logo-Poli">
        <LogoPoli />
      </div>
      <div className="header">
        <h1 className="title-h1">QUIENES SOMOS</h1>
      </div>
      <div class="container">
        <div class="decano">
          <section>
            <figure>
              <img src={decano} alt="Decano" />
            </figure>
            <article>
              <h2>DECANO FIE</h2>
              <p>Ing. Washington Gilberto Lina Ph.D.</p>
            </article>
          </section>
        </div>
        <div class="Cordinador">
          <section>
            <figure>
              <img src={coordinador} alt="Cordinador" />
            </figure>
            <article>
              <h2>COORDINADOR</h2>
              <p>Ing. Víctor Fernando Proaño Brito</p>
            </article>
          </section>
        </div>
        <div class="programador1">
          <section>
            <figure>
              <img src={unity} alt="unity" />
            </figure>
            <article>
              <h2>DESARROLLADOR UNITY</h2>
              <p>Cristian Daniel Proaño Ocaña</p>
            </article>
          </section>
        </div>
        <div class="programador2">
          <section>
            <figure>
              <img src={web} alt="web" />
            </figure>
            <article>
              <h2>DESARROLLADOR WEB</h2>
              <p>Ruben Dario Valencia Navarrete</p>
            </article>
          </section>
        </div>
        <div class="disenador1">
          <section>
            <figure>
              <img src={dise1} alt="dise1" />
            </figure>
            <article>
              <h2>DISEÑADOR</h2>
              <p>Freddy Moisés Miranda Gualli</p>
            </article>
          </section>
        </div>
        <div class="disenador2">
          <section>
            <figure>
              <img src={dise2} alt="dise2" />
            </figure>
            <article>
              <h2>DISEÑADOR</h2>
              <p>Edison Geovanny Tixi Socoy</p>
            </article>
          </section>
        </div>
      </div>
      <div className="footer">
        <Cincuenta />
        <div className="content-regresar">
          <a href="/paseo" className="btn-regresar buttonR">
            <span>REGRESAR</span>
          </a>
        </div>
        <Muvi />
      </div>
    </div>
  );
};

export default QieneSomos;
