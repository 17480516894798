import "./origami.css";

const Forigami = () => {
  return (
    <>
      <svg viewBox="0 0 1367 605">
        <defs>
          <linearGradient
            id="Degradado_sin_nombre"
            y1="244.22"
            x2="1367"
            y2="244.22"
            gradientTransform="matrix(1, 0, 0, -1, 0, 439.22)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#d8f0fe" />
            <stop offset="0.4" stop-color="#d6effe" />
            <stop offset="0.58" stop-color="#cfecfe" />
            <stop offset="0.71" stop-color="#c2e7fd" />
            <stop offset="0.83" stop-color="#b0e0fd" />
            <stop offset="0.92" stop-color="#99d6fd" />
            <stop offset="1" stop-color="#81cdfc" />
          </linearGradient>
        </defs>
        <g id="Capa_2" data-name="Capa 2">
          <g id="Capa_1-2" data-name="Capa 1">
            <rect class="cls10-1" width="1367" height="390" />
            <polyline
              class="cls10-2"
              points="173.59 592.03 170.74 605 137.3 603.96 0.76 423 238 426.62 173.59 592.03"
            />
            <polyline
              class="cls10-2"
              points="1360 448.02 1230 604 1230 591.54 1178.9 459.95 1206.23 428.76 1360 448.02"
            />
            <polygon
              class="cls10-2"
              points="1365.93 440 1367 602.95 1227 604 1365.93 440"
            />
            <polygon
              class="cls10-2"
              points="142.76 602.98 0 604 0 425.61 5.58 425.61 142.76 602.98"
            />
            <polygon
              class="cls10-3"
              points="469.02 603 352.6 603 450.1 488.43 536.32 419.53 769.04 425.22 824 444.59 469.02 603"
            />
            <polygon
              class="cls10-4"
              points="640.9 195.88 585.79 194.22 616.86 175.34 640.9 195.88"
            />
            <polygon
              class="cls10-5"
              points="556.83 141.73 548.02 173.83 522.83 188.93 503.04 168.49 556.83 141.73"
            />
            <polygon
              class="cls10-6"
              points="522.83 188.93 453.57 193.09 503.04 168.49 522.83 188.93"
            />
            <polygon
              class="cls10-6"
              points="453.15 225.95 308.75 245.59 349.47 231.61 415.79 214.24 453.15 225.95"
            />
            <polygon
              class="cls10-4"
              points="522.83 188.93 453.15 225.95 435.94 196.86 453.57 193.09 522.83 188.93"
            />
            <polygon
              class="cls10-5"
              points="522.83 188.93 485.05 225.19 418.73 253.14 453.15 225.95 522.83 188.93"
            />
            <polygon
              class="cls10-5"
              points="783.92 226.7 698.71 252.52 683.18 231.99 726.83 213.86 783.92 226.7"
            />
            <polygon
              class="cls10-4"
              points="861.16 237.28 828.41 251.63 783.92 226.7 861.16 237.28"
            />
            <polygon
              class="cls10-4"
              points="736.49 128.88 694.93 151.17 720.54 115.29 736.49 128.88"
            />
            <polygon
              class="cls10-5"
              points="720.54 115.29 694.93 151.17 650.44 140.59 626.51 101.69 720.54 115.29"
            />
            <polygon
              class="cls10-5"
              points="797.77 150.03 783.92 167.78 758.74 148.9 762.51 122.84 797.77 150.03"
            />
            <polygon
              class="cls10-4"
              points="626.51 101.69 610.14 144.37 598.39 154.94 556.83 141.73 626.51 101.69"
            />
            <polygon
              class="cls10-6"
              points="598.39 154.94 548.02 173.83 556.83 141.73 598.39 154.94"
            />
            <polygon
              class="cls10-6"
              points="616.86 175.34 585.79 194.22 598.39 154.94 610.14 144.37 616.86 175.34"
            />
            <polygon
              class="cls10-5"
              points="661.35 179.87 640.9 195.88 616.86 175.34 661.35 179.87"
            />
            <polygon
              class="cls10-4"
              points="783.92 167.78 726.83 213.86 758.74 148.9 783.92 167.78"
            />
            <polygon
              class="cls10-5"
              points="758.74 148.9 726.83 213.86 694.93 151.17 758.74 148.9"
            />
            <polygon
              class="cls10-4"
              points="726.83 213.86 683.18 231.99 694.93 151.17 726.83 213.86"
            />
            <polygon
              class="cls10-6"
              points="694.93 151.17 661.35 179.87 650.44 140.59 694.93 151.17"
            />
            <polygon
              class="cls10-6"
              points="797.77 150.03 793.08 176.03 783.92 167.78 797.77 150.03"
            />
            <polygon
              class="cls10-4"
              points="914.47 208.19 797.77 150.03 811.21 143.24 914.47 208.19"
            />
            <polygon
              class="cls10-6"
              points="954.34 219.9 897.25 231.23 914.47 208.19 928.32 201.78 954.34 219.9"
            />
            <polygon
              class="cls10-5"
              points="914.47 208.19 897.25 231.23 884.66 247.85 861.16 237.28 914.47 208.19"
            />
            <polygon
              class="cls10-4"
              points="1041.65 249.36 972.81 238.79 954.34 219.9 1041.65 249.36"
            />
            <path
              class="cls10-7"
              d="M567.11,377l-9.86-1.5A46.28,46.28,0,0,1,567.11,377Z"
            />
            <path
              class="cls10-8"
              d="M553.23,367.83c-4.73,3.43-14,1.24-14.3-6.93,0,0-.16-8.18,8.88-9.35l4.78.87h0c2,1,4,3.18,4.82,7.58C557.43,360,558,364.39,553.23,367.83Z"
            />
            <path
              class="cls10-7"
              d="M552.59,352.41h0l-4.78-.87A8.52,8.52,0,0,1,552.59,352.41Z"
            />
            <line
              class="cls10-7"
              x1="552.68"
              y1="369.6"
              x2="549.6"
              y2="369.32"
            />
            <line
              class="cls10-7"
              x1="557.25"
              y1="375.45"
              x2="556.34"
              y2="375.32"
            />
            <line
              class="cls10-7"
              x1="591.81"
              y1="380.76"
              x2="591.69"
              y2="380.72"
            />
            <path class="cls10-7" d="M586.39,352.78h0" />
            <line
              class="cls10-7"
              x1="708.63"
              y1="395.4"
              x2="708.41"
              y2="395.26"
            />
            <path
              class="cls10-7"
              d="M633.76,521.26a19.11,19.11,0,0,1-7.8,2.08Z"
            />
            <line
              class="cls10-7"
              x1="709.11"
              y1="410.34"
              x2="719.54"
              y2="414.43"
            />
            <polygon
              class="cls10-6"
              points="650.44 140.59 610.14 144.37 626.51 101.69 650.44 140.59"
            />
            <polygon
              class="cls10-6"
              points="762.51 122.84 758.74 148.9 694.93 151.17 736.49 128.88 762.51 122.84"
            />
            <polygon
              class="cls10-4"
              points="598.39 154.94 585.79 194.22 542.98 199.51 485.05 225.19 522.83 188.93 548.02 173.83 598.39 154.94"
            />
            <polygon
              class="cls10-5"
              points="453.15 225.95 415.79 214.24 435.94 196.86 453.15 225.95"
            />
            <path
              class="cls10-5"
              d="M638.23,261.45l-41.52-31,44.19-34.6C640.84,196.79,637,259.2,638.23,261.45Z"
            />
            <path
              class="cls10-4"
              d="M683.18,232l-44.95,29.46c-1.22-2.25,2.61-64.66,2.67-65.57h0Z"
            />
            <polygon
              class="cls10-3"
              points="1121.79 277.69 945.95 261.45 933.36 245.59 995.48 252.38 972.81 238.79 1041.65 249.36 1121.79 277.69"
            />
            <polygon
              class="cls10-9"
              points="1218 261.46 1121.79 277.69 1212.23 245.59 1218 261.46"
            />
            <polygon
              class="cls10-4"
              points="1236 258.43 1218 261.46 1212.23 245.59 1236 258.43"
            />
            <polygon
              class="cls10-5"
              points="1236 298.84 1236 311.06 1232.17 309.94 1212.15 304.09 1197.79 299.9 1231.61 298.96 1236 298.84"
            />
            <polygon
              class="cls10-6"
              points="683.18 231.99 640.9 195.88 661.35 179.87 683.18 231.99"
            />
            <polygon
              class="cls10-5"
              points="650.44 140.59 616.86 175.34 610.14 144.37 650.44 140.59"
            />
            <polygon
              class="cls10-6"
              points="861.16 237.28 783.92 226.7 793.08 176.03 861.16 237.28"
            />
            <polygon
              class="cls10-5"
              points="793.08 176.03 783.92 226.7 783.92 167.78 793.08 176.03"
            />
            <polygon
              class="cls10-6"
              points="783.92 167.78 783.92 226.7 726.83 213.86 783.92 167.78"
            />
            <polygon
              class="cls10-4"
              points="914.47 208.19 861.16 237.28 793.08 176.03 914.47 208.19"
            />
            <polygon
              class="cls10-5"
              points="914.47 208.19 793.08 176.03 797.77 150.03 914.47 208.19"
            />
            <polygon
              class="cls10-4"
              points="661.35 179.87 616.86 175.34 650.44 140.59 661.35 179.87"
            />
            <polygon
              class="cls10-5"
              points="694.93 151.17 683.18 231.99 661.35 179.87 694.93 151.17"
            />
            <polygon
              class="cls10-5"
              points="933.36 245.59 945.95 261.45 897.25 231.23 954.34 219.9 972.81 238.79 995.48 252.38 933.36 245.59"
            />
            <polygon
              class="cls10-5"
              points="542.98 199.51 523.67 258.43 485.05 225.19 542.98 199.51"
            />
            <polygon
              class="cls10-4"
              points="585.79 194.22 523.67 258.43 542.98 199.51 585.79 194.22"
            />
            <polygon
              class="cls10-8"
              points="189.74 277.69 161.42 283.04 161.42 261.64 189.74 277.69"
            />
            <polygon
              class="cls10-3"
              points="380.57 295.81 189.74 277.69 308.75 245.59 380.57 295.81"
            />
            <polygon
              class="cls10-8"
              points="453.15 225.95 418.73 253.14 380.57 295.81 308.75 245.59 453.15 225.95"
            />
            <path
              class="cls10-10"
              d="M634,521.2l-25.08,12.11-.4-6.75L618,522a19.21,19.21,0,0,0,15.77-.77h0Z"
            />
            <path
              class="cls10-7"
              d="M633.76,521.26a19.11,19.11,0,0,1-7.8,2.08Z"
            />
            <polygon
              class="cls10-7"
              points="749.71 459.2 749.68 459.88 748.17 459.95 749.71 459.2"
            />
            <path
              class="cls10-6"
              d="M640.9,195.88h0l-44.14,34.6-73,28,62-64.21Z"
            />
            <polygon
              class="cls10-3"
              points="638.23 261.45 603.18 312.8 602.66 313.56 602.24 314.17 601.86 313.89 599.92 312.52 523.67 258.43 596.71 230.48 638.23 261.45"
            />
            <polygon
              class="cls10-11"
              points="380.57 295.82 242.15 358.37 242.14 358.37 241.95 358.46 238.19 360.16 231.36 348.54 189.74 277.69 379.32 295.7 379.33 295.7 380.57 295.82"
            />
            <polygon
              class="cls10-3"
              points="238.19 360.16 161.42 283.04 189.74 277.69 231.36 348.54 238.19 360.16"
            />
            <polygon
              class="cls10-3"
              points="500.02 539.55 352.6 604 161 604 161 591.54 163.7 591.54 231.52 566.75 260.84 556.04 298.68 542.2 348.21 524.1 348.21 514.89 373.4 514.89 436.36 491.88 444.76 488.81 471.62 488.81 461.11 493.49 462.22 512.08 467.28 512.87 468.67 535.49 472.11 536.83 481.19 534.13 479.97 513.77 485 513.52 485.09 516.92 481.13 519 481.27 521.27 485.5 521.82 489.08 525.43 489.36 535.69 488.08 536.82 500.02 539.55"
            />
            <polygon
              class="cls10-2"
              points="241.95 358.46 238.19 360.16 161.42 283.04 231.36 348.54 241.46 358 241.9 358.41 241.95 358.46"
            />
            <polygon
              class="cls10-2"
              points="473.57 367.54 473.3 367.65 440.64 358.46 473.57 367.54"
            />
            <path
              class="cls10-3"
              d="M601.85,313.89,564.7,329.43h0a40.65,40.65,0,0,0-11.57,2.09v.88c-1.11.32-1.72.55-1.72.55l.11,2-78,32.61-.27.11-32.66-9.19L370,338.61l-127.84,19.8,138.42-62.55,4.56-1.19,214.79,17.9Z"
            />
            <polygon
              class="cls10-7"
              points="382.25 294.38 385.13 294.62 380.57 295.81 242.15 358.36 242.14 358.36 239.86 358.72 379.32 295.69 379.33 295.69 379.98 295.41 381.57 294.68 381.58 294.68 382.25 294.38"
            />
            <path
              class="cls10-7"
              d="M553.26,334.21l-1.72.72-.12-2s.62-.24,1.73-.55Z"
            />
            <polygon
              class="cls10-7"
              points="604.26 312.89 602.66 313.56 601.86 313.89 599.92 312.52 603.18 312.8 604.26 312.89"
            />
            <polygon
              class="cls10-8"
              points="599.92 312.52 385.13 294.62 523.67 258.43 599.92 312.52"
            />
            <polygon
              class="cls10-11"
              points="523.67 258.43 385.13 294.62 380.57 295.81 381.58 294.68 418.73 253.14 485.05 225.19 523.67 258.43"
            />
            <polygon
              class="cls10-11"
              points="972.81 314.17 929.69 335.92 898.93 336.23 874.91 336.23 602.24 314.17 602.66 313.56 604.26 312.89 603.18 312.8 638.23 261.45 863.87 295.81 972.81 314.17"
            />
            <polygon
              class="cls10-7"
              points="604.26 312.89 602.66 313.56 603.18 312.8 604.26 312.89"
            />
            <path
              class="cls10-12"
              d="M874.91,336.23h-.32v16l-8.8,2.28h0l-4.62-3.13H842.69v9.12L832.91,363l-10.37-2.6H798.19V372l-84.94,22-4.89-.14-.25-7.25-12.51-9.09-2,1.07a13.12,13.12,0,0,0-5.78-2.52c-9.4-2-26.39-5-36.4-3.74a43.13,43.13,0,0,0-11.66-1.08l-.29-2.9s-.4-6.8-23.93-6.41l-.22-3.81s-3.76-6.59-14.7-6.82c-10.62-.22-14,1.35-14.2,1.44a26.31,26.31,0,0,0-3.6-18s-.13-2.27-11.63-3.66a49.18,49.18,0,0,0-8.14-.29h0a45.43,45.43,0,0,0-9.84,1.53v-.88a40.65,40.65,0,0,1,11.57-2.09h0l37.15-15.54.39.28Z"
            />
            <path
              class="cls10-3"
              d="M1236,591.54V604H462.65l142.22-68.72,4.2.23-.14-2.2L634,521.2h0l12.66-3.38c2.2-.69,3.85-2.56,4.91-5.11l4.8-2.32c3.05,4.45,5.94,2.13,5.94,2.13,8.25-2.66,7.93-7.94,7.93-7.94.08-.31.16-.64.23-1L707,485.92v.2l7-.34-1.33-2.6,51.18-24.73-11.07-.71-3.06,1.47.45-10.7-2-11.83,13.88-4.07,25.19,8.44,10.91,3.67,24.35,8.16,20.15,6.73,18.47,6.19,13.43,4.51,24.34,8.16,11.76,3.94,42.81,14.36,7.56,2.53,80.59,27,8.4,2.81,46.29,15.53,30.1,10.09,83.12,27.87Z"
            />
            <polygon
              class="cls10-10"
              points="1202.87 519.37 640.75 556.43 575.97 554.19 1151.34 516.99 1202.87 519.37"
            />
            <polygon
              class="cls10-10"
              points="818.06 510.93 240.83 542.59 207.27 541.43 789.77 508.6 818.06 510.93"
            />
            <polygon
              class="cls10-13"
              points="1151.34 516.99 575.97 554.19 240.83 542.59 818.06 510.93 903.7 518 1105.41 518.27 1118.76 519.07 1117.86 515.45 1151.34 516.99"
            />
            <polygon
              class="cls10-14"
              points="739.17 419.53 732.49 415.68 731.33 415.73 731.07 411.13 726.57 409.5 723.96 404.43 735.6 413.66 739.17 419.53"
            />
            <path
              class="cls10-15"
              d="M798.19,372v11.28l-10.91-1.73H762.09v51.06l-13.92,4.06-1.93-11.43-7.07-5.71-3.57-5.88L724,404.42l-11.43.55a13.26,13.26,0,0,0-3.86-2.09h0l-.25-7.62v-1.4l4.89.14Z"
            />
            <line
              class="cls10-7"
              x1="937.22"
              y1="371.35"
              x2="937.22"
              y2="370.97"
            />
            <line
              class="cls10-7"
              x1="987.06"
              y1="377.53"
              x2="987.06"
              y2="377.01"
            />
            <line
              class="cls10-7"
              x1="436.36"
              y1="406.78"
              x2="435.94"
              y2="406.65"
            />
            <line
              class="cls10-7"
              x1="348.21"
              y1="394.39"
              x2="330.01"
              y2="394.39"
            />
            <polygon
              class="cls10-2"
              points="1212.15 304.09 1090.6 300.89 1090.59 300.89 1070.73 300.37 1054.34 299.94 1121.79 277.69 1197.79 299.9 1212.15 304.09"
            />
            <polygon
              class="cls10-8"
              points="972.81 314.17 863.87 295.81 638.23 261.45 683.18 231.99 698.71 252.52 783.92 226.7 828.41 251.63 972.81 314.17"
            />
            <polygon
              class="cls10-16"
              points="929.69 335.93 921.6 340.01 898.93 338.17 874.91 336.23 898.93 336.23 929.69 335.93"
            />
            <polygon
              class="cls10-3"
              points="1121.79 277.69 1054.34 299.94 937.22 338.57 921.6 340.01 929.69 335.93 972.81 314.17 1033.21 299.38 1121.79 277.69"
            />
            <polygon
              class="cls10-11"
              points="1121.79 277.69 972.81 314.17 828.41 251.63 861.16 237.28 884.66 247.85 897.25 231.23 945.95 261.45 1121.79 277.69"
            />
            <line
              class="cls10-17"
              x1="514.02"
              y1="442.79"
              x2="510.02"
              y2="444.18"
            />
            <polygon
              class="cls10-18"
              points="0 177.93 0 221.36 779 197.19 0 177.93"
            />
            <polygon
              class="cls10-9"
              points="1236 258.43 1236 298.84 1231.61 298.96 1218 261.46 1236 258.43"
            />
            <polygon
              class="cls10-2"
              points="1245.2 304.96 1236 304.72 1236 311.06 1232.17 309.94 1231.9 304.61 1231.61 298.96 1236 300.9 1245.2 304.96"
            />
            <polygon
              class="cls10-4"
              points="1231.61 298.96 1197.79 299.89 1218 261.46 1231.61 298.96"
            />
            <polygon
              class="cls10-5"
              points="1218 261.46 1197.79 299.89 1197.79 299.89 1121.79 277.69 1218 261.46"
            />
            <path
              class="cls10-2"
              d="M553.23,367.83l-.55,1.77-1.92,6.1c-13.72,1.77-23.34,6.67-30.08,12.72a45.51,45.51,0,0,0-10.74,14.47,52.32,52.32,0,0,0-5.06,21V424A47,47,0,0,0,510,444.17l-13.21,4.6-11.34-.49-6.29-.27.32,17.89-5.76,1h11.73v.76c-5.33,0-9.65,4.74-9.65,10.57a11.18,11.18,0,0,0,1.74,6l-5.94,4.53H444.76l-8.4,3.07-63,23H348.21V524l-49.53,18.1L260.84,556l-29.32,10.71L163.7,591.48c82.71-175.47,32.56-162.4,32.56-162.4H0v-118l161.42-28,76.77,77.12,3.76-1.7H242l127.61-19.64,71,19.59,32.68,9.18.27-.11h.15l77.86-31.89,1,16.76a8.6,8.6,0,0,0-4.78-.86c-9,1.17-8.88,9.35-8.88,9.35C539.26,369.07,548.5,371.26,553.23,367.83Z"
            />
            <path
              class="cls10-2"
              d="M1236,591.54l-26.44-8.87-83.12-27.87-30.1-10.09-46.29-15.53-8.4-2.81-80.59-27-7.56-2.53-42.81-14.36-11.76-3.94-24.34-8.16-13.43-4.51-18.47-6.26-20.15-6.76-24.35-8.16L787.28,441l-25.19-8.41V381.55h25.19l10.91,1.73V360.4h24.35l10.37,2.6,9.78-2.54v-9.12h18.47l4.62,3.13h0l8.8-2.28v-16h.32l24,1.94L921.57,340l15.65-1.36L1054.34,300l16.39.43,19.86.52h0l121.55,3.2,20,5.85,3.83,1.12v-6.34l9.2.24,121.62,53.76-.82,89.3-133-5S1197,440.07,1236,591.54Z"
            />
            <polygon
              class="cls10-18"
              points="1366.82 284.84 1366.82 324.17 1245.2 320.96 1236 320.72 1231.9 320.61 1212.15 320.09 1090.6 316.89 1090.59 316.89 1070.73 316.37 1054.34 315.94 1033.21 315.38 932.53 312.72 856.68 310.72 637 304.93 790.84 300.7 897.95 297.75 1085.36 292.59 1104.25 292.07 1116.27 291.74 1128.22 291.41 1136.71 291.18 1140.16 291.08 1171.69 290.21 1211.88 289.11 1222.12 288.82 1236 288.44 1328.5 285.9 1328.51 285.9 1362.42 284.96 1366.82 284.84"
            />
            <polygon
              class="cls10-12"
              points="713.25 394.01 746.24 425.25 536.32 419.53 466.54 475.29 544.27 337.97 585.79 320.6 731.57 389.26 713.25 394.01"
            />
            <polygon
              class="cls10-19"
              points="111.56 571.34 111.56 571.34 111.56 571.34 111.56 571.34 111.67 571.34 111.56 571.34"
            />
            <polygon
              class="cls10-19"
              points="111.56 568.05 111.56 568.05 111.56 568.05 111.56 568.05 111.67 568.05 111.56 568.05 111.56 568.05"
            />
            <path
              class="cls10-17"
              d="M200.9,572.77a3.46,3.46,0,1,0,3.4,3.52h0v-.11a3.49,3.49,0,0,0-3.29-3.51A.11.11,0,0,1,200.9,572.77Z"
            />
            <polygon
              class="cls10-17"
              points="184.11 577.49 187.62 577.49 185.86 572.99 184.11 577.49"
            />
            <path
              class="cls10-17"
              d="M176.09,572.77a3.46,3.46,0,1,0,3.4,3.52h0a3.49,3.49,0,0,0-3.29-3.51Z"
            />
            <polygon
              class="cls10-20"
              points="497.06 425.51 479.71 493.44 471.95 501.89 442.13 465.79 438.47 457.42 474.48 438.5 481.89 439.11 490.26 430.22 497.06 425.51"
            />
            <path
              class="cls10-21"
              d="M544.24,383.31l-37.76,31.21L450.66,363l-6.81,4.71-35.2,26.42,7.17-26.42s110-63.56,115.77-66.7,6.27,2.09,6.27,2.09l1,83.71Z"
            />
            <polygon
              class="cls10-22"
              points="497.06 425.51 490.26 430.22 437.42 426.03 389.29 407.2 408.65 394.12 443.86 367.7 450.66 362.99 497.06 425.51"
            />
            <polygon
              class="cls10-20"
              points="442.13 465.79 409.17 480.44 394.36 431.96 410.53 433.28 435.56 454.92 442.13 465.79"
            />
            <polygon
              class="cls10-23"
              points="409.17 480.44 363.1 429.41 394.36 431.96 409.17 480.44"
            />
            <polygon
              class="cls10-23"
              points="372.03 405.63 342.4 406.48 253.79 308.32 372.03 405.63"
            />
            <polygon
              class="cls10-20"
              points="442.13 465.79 435.56 454.92 423.13 434.31 438.47 457.42 442.13 465.79"
            />
            <polygon
              class="cls10-17"
              points="451.29 436.61 434.88 435.27 434.8 434.79 451.29 436.61"
            />
            <polygon
              class="cls10-22"
              points="576.34 388.19 483.04 489.82 545.48 382.5 554.08 376.86 576.34 388.19"
            />
            <path
              class="cls10-21"
              d="M638.3,419.75h0c-1.3-1-43-31.43-49.15-35.55a23.87,23.87,0,0,0-12-4.18l-23-3.14,35-.53Z"
            />
            <polygon
              class="cls10-21"
              points="363.1 429.41 296.17 423.94 284.14 420.28 335.41 406.68 342.4 406.48 363.1 429.41"
            />
            <polygon
              class="cls10-24"
              points="490.26 430.22 410.54 433.28 410.53 433.28 394.36 431.96 363.1 429.41 342.4 406.48 372.03 405.63 389.29 407.2 437.42 426.03 490.26 430.22"
            />
            <polygon
              class="cls10-25"
              points="451.29 436.61 434.88 435.27 434.8 434.79 451.29 436.61"
            />
            <polygon
              class="cls10-23"
              points="545.48 382.5 483.04 489.82 479.71 493.44 497.06 425.51 506.48 414.52 544.24 383.31 545.48 382.5"
            />
            <polygon
              class="cls10-20"
              points="506.48 414.52 497.06 425.51 450.66 362.99 506.48 414.52"
            />
            <polygon
              class="cls10-21"
              points="483.04 489.82 478.75 497.18 479.71 493.44 483.04 489.82"
            />
            <polygon
              class="cls10-21"
              points="545.71 382.09 545.48 382.5 544.24 383.31 545.71 382.09"
            />
            <polygon
              class="cls10-23"
              points="490.26 430.22 481.89 439.11 474.48 438.5 451.29 436.61 434.8 434.79 434.88 435.27 423.13 434.31 410.54 433.28 490.26 430.22"
            />
            <polygon
              class="cls10-21"
              points="438.47 457.42 423.13 434.31 434.88 435.27 438.47 457.42"
            />
            <polygon
              class="cls10-22"
              points="435.56 454.92 410.53 433.28 410.54 433.28 423.13 434.31 435.56 454.92"
            />
            <polygon
              class="cls10-25"
              points="438.47 457.42 435.56 454.92 423.13 434.31 438.47 457.42"
            />
            <polygon
              class="cls10-25"
              points="451.29 436.61 434.88 435.27 434.8 434.79 451.29 436.61"
            />
            <polygon
              class="cls10-22"
              points="474.48 438.5 438.47 457.42 451.29 436.61 474.48 438.5"
            />
            <path
              class="cls10-20"
              d="M638.28,419.73l-61.94-31.54-22.26-11.33,23,3.14a23.87,23.87,0,0,1,12,4.18C595.31,388.3,637,418.78,638.28,419.73Z"
            />
            <line
              class="cls10-17"
              x1="394.36"
              y1="431.96"
              x2="393.83"
              y2="430.22"
            />
            <polygon
              class="cls10-20"
              points="442.13 465.79 435.56 454.92 423.13 434.31 438.47 457.42 442.13 465.79"
            />
            <polygon
              class="cls10-20"
              points="451.29 436.61 438.47 457.42 434.88 435.27 451.29 436.61"
            />
            <polygon
              class="cls10-25"
              points="451.29 436.61 434.88 435.27 434.8 434.79 451.29 436.61"
            />
            <polygon
              class="cls10-26"
              points="1080.94 134.14 1001.62 188.22 982.58 178.84 1002.6 148.44 1080.94 134.14"
            />
            <polygon
              class="cls10-27"
              points="1045.98 251 1041.13 272.59 1039.72 273.73 1017.07 258.63 978.35 193.46 970.12 165.01 982.58 178.84 1026.55 233.69 1045.98 251"
            />
            <polygon
              class="cls10-26"
              points="939.77 169.85 929.75 189.11 931.1 194.38 913.13 174.34 926.25 152.32 939.77 169.85"
            />
            <polygon
              class="cls10-28"
              points="919.15 109.99 926.25 152.32 913.13 174.34 910.59 111.46 919.15 109.99"
            />
            <polygon
              class="cls10-28"
              points="939.38 130.3 926.25 152.32 919.15 109.99 927.95 108.46 939.38 130.3"
            />
            <path
              class="cls10-26"
              d="M964.22,161.75l-15.67,3.12-8.78,5-13.52-17.53,13.13-22C963.1,151.24,964.17,160.91,964.22,161.75Z"
            />
            <path
              class="cls10-28"
              d="M970.12,165l8.23,28.45L962.76,171.4l-11-5.71-12,4.16,8.78-5,15.67-3.12v.06Z"
            />
            <polygon
              class="cls10-28"
              points="920.99 78.85 925.15 90.79 927.95 108.46 919.15 109.99 917.57 100.62 920.99 78.85"
            />
            <polygon
              class="cls10-28"
              points="920.99 78.85 917.57 100.62 919.15 109.99 910.59 111.46 918.3 79.79 920.99 78.85"
            />
            <line
              class="cls10-29"
              x1="964.65"
              y1="161.66"
              x2="964.22"
              y2="161.75"
            />
            <polygon
              class="cls10-26"
              points="1196.44 79.02 1167.12 118.4 1080.94 134.14 1159.44 71.11 1159.45 71.11 1196.44 79.02"
            />
            <polygon
              class="cls10-26"
              points="1167.12 118.4 1147.7 158.25 1109.82 200.99 1065.92 217.71 1021.25 197.89 1001.62 188.22 1080.94 134.14 1080.94 134.14 1167.12 118.4"
            />
            <polygon
              class="cls10-28"
              points="1113.49 325.45 1111.36 337.19 1093.42 357.78 1087.58 352.64 1056.35 260.25 1057.32 260.98 1113.49 325.45"
            />
            <polygon
              class="cls10-28"
              points="1087.58 352.64 1080.85 362.16 1051.78 364.48 1042.54 356.55 1051.93 281.87 1087.58 352.64"
            />
            <polygon
              class="cls10-28"
              points="1024.29 286.23 1042.54 356.55 1035.46 363.62 997.35 360.58 993.65 349.97 1024.29 286.23"
            />
            <polygon
              class="cls10-28"
              points="1024.29 286.23 993.65 349.97 980.22 354.65 946.84 335.68 943.43 325.87 1003.53 274.27 1024.29 286.23"
            />
            <polygon
              class="cls10-26"
              points="1003.53 274.27 943.43 325.87 936.13 322.77 933.87 316.29 941.04 305.3 999.78 272.11 1003.53 274.27"
            />
            <polygon
              class="cls10-26"
              points="999.78 272.11 999.78 272.11 941.04 305.3 938.63 298.37 957.08 258 969.65 247.41 972.51 258.37 993.78 268.65 999.78 272.11"
            />
            <polygon
              class="cls10-27"
              points="1039.72 273.73 1024.29 286.23 1003.53 274.27 999.78 272.11 999.78 272.11 972.34 230.7 959.07 225.28 937.47 222.28 945.45 212.81 978.35 193.46 1017.07 258.63 1039.72 273.73"
            />
            <polygon
              class="cls10-28"
              points="978.35 193.46 945.45 212.81 929.42 218.39 933.55 206.91 931.1 194.38 929.75 189.11 939.67 185.66 955.55 188.26 978.35 193.46"
            />
            <path
              class="cls10-28"
              d="M945.45,212.81l-8,9.47-21.26,21.33-23.43,33s-1.54,2.45-4.43-.37l22.43-34.63,18.68-23.24Z"
            />
            <polygon
              class="cls10-26"
              points="1113.81 296.17 1112.42 303.16 1057.32 260.98 1056.14 259.63 1048.78 238.5 1082.96 252.89 1113.81 296.17"
            />
            <polygon
              class="cls10-30"
              points="1048.78 238.5 1045.98 251 1026.55 233.69 1021.25 197.89 1027 211.66 1048.78 238.5"
            />
            <polygon
              class="cls10-30"
              points="1082.96 252.89 1048.78 238.5 1027 211.66 1082.96 252.89"
            />
            <polygon
              class="cls10-28"
              points="1196.5 51.75 1196.44 79.02 1159.45 71.11 1177.64 51.15 1196.5 51.75"
            />
            <polygon
              class="cls10-28"
              points="1177.64 51.15 1159.45 71.11 1159.44 71.11 1146.65 68.37 1177.64 51.15"
            />
            <polygon
              class="cls10-30"
              points="1021.25 197.89 1026.55 233.69 982.58 178.84 1001.62 188.22 1021.25 197.89"
            />
            <polygon
              class="cls10-30"
              points="1056.35 260.25 1051.93 281.87 1040.72 274.4 1041.13 272.59 1056.35 260.25"
            />
            <polygon
              class="cls10-26"
              points="1112.42 303.16 1119.01 322.09 1113.49 325.45 1057.32 260.98 1112.42 303.16"
            />
            <polygon
              class="cls10-28"
              points="1056.35 260.25 1087.58 352.64 1051.93 281.87 1056.35 260.25"
            />
            <line
              class="cls10-29"
              x1="1056.14"
              y1="259.63"
              x2="1055.39"
              y2="258.77"
            />
            <polygon
              class="cls10-30"
              points="1048.78 238.5 1056.35 260.25 1045.98 251 1048.78 238.5"
            />
            <polygon
              class="cls10-30"
              points="1056.35 260.25 1041.13 272.59 1045.98 251 1056.35 260.25"
            />
            <polygon
              class="cls10-30"
              points="1051.93 281.87 1024.29 286.23 1039.72 273.73 1040.72 274.4 1051.93 281.87"
            />
            <polygon
              class="cls10-28"
              points="1051.93 281.87 1042.54 356.55 1024.29 286.23 1051.93 281.87"
            />
            <polygon
              class="cls10-28"
              points="1159.44 71.11 1080.94 134.14 1080.94 134.14 1002.6 148.44 1146.65 68.37 1159.44 71.11"
            />
            <polygon
              class="cls10-28"
              points="978.35 193.46 955.55 188.26 939.67 185.66 929.75 189.11 939.77 169.85 951.71 165.69 962.78 171.4 978.35 193.46"
            />
            <polygon
              class="cls10-30"
              points="999.78 272.11 993.78 268.65 972.51 258.37 969.65 247.41 959.07 225.28 972.34 230.7 999.78 272.11"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default Forigami;
