import "./comunicacion.css";

const Fcomunicacion = () => {
  return (
    <>
      <svg viewBox="0 0 1367 604">
        <defs>
          <linearGradient
            id="Degradado_sin_nombre"
            y1="244.22"
            x2="1367"
            y2="244.22"
            gradientTransform="matrix(1, 0, 0, -1, 0, 439.22)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#d8f0fe" />
            <stop offset="0.4" stop-color="#d6effe" />
            <stop offset="0.58" stop-color="#cfecfe" />
            <stop offset="0.71" stop-color="#c2e7fd" />
            <stop offset="0.83" stop-color="#b0e0fd" />
            <stop offset="0.92" stop-color="#99d6fd" />
            <stop offset="1" stop-color="#81cdfc" />
          </linearGradient>
        </defs>
        <g id="Capa_2" data-name="Capa 2">
          <g id="Capa_1-2" data-name="Capa 1">
            <rect class="cls9-1" width="1367" height="390" />
            <polyline
              class="cls9-2"
              points="169.01 591.03 166.23 604 133.53 602.96 0 422 232 425.62 169.01 591.03"
            />
            <polyline
              class="cls9-2"
              points="1366 448.02 1236 604 1236 591.54 1184.9 459.95 1212.23 428.76 1366 448.02"
            />
            <polygon
              class="cls9-2"
              points="1366 448.02 1367 603 1236 604 1366 448.02"
            />
            <polygon
              class="cls9-2"
              points="134.81 603 0 604 0 429.14 5.27 429.14 134.81 603"
            />
            <polygon
              class="cls9-3"
              points="469.02 603 352.6 603 450.1 488.43 536.32 419.53 769.04 425.22 824 444.59 469.02 603"
            />
            <polygon
              class="cls9-4"
              points="640.9 195.88 585.79 194.22 616.86 175.34 640.9 195.88"
            />
            <polygon
              class="cls9-5"
              points="556.83 141.73 548.02 173.83 522.83 188.93 503.04 168.49 556.83 141.73"
            />
            <polygon
              class="cls9-6"
              points="522.83 188.93 453.57 193.09 503.04 168.49 522.83 188.93"
            />
            <polygon
              class="cls9-6"
              points="453.15 225.95 308.75 245.59 349.47 231.61 415.79 214.24 453.15 225.95"
            />
            <polygon
              class="cls9-4"
              points="522.83 188.93 453.15 225.95 435.94 196.86 453.57 193.09 522.83 188.93"
            />
            <polygon
              class="cls9-5"
              points="522.83 188.93 485.05 225.19 418.73 253.14 453.15 225.95 522.83 188.93"
            />
            <polygon
              class="cls9-5"
              points="783.92 226.7 698.71 252.52 683.18 231.99 726.83 213.86 783.92 226.7"
            />
            <polygon
              class="cls9-4"
              points="861.16 237.28 828.41 251.63 783.92 226.7 861.16 237.28"
            />
            <polygon
              class="cls9-4"
              points="736.49 128.88 694.93 151.17 720.54 115.29 736.49 128.88"
            />
            <polygon
              class="cls9-5"
              points="720.54 115.29 694.93 151.17 650.44 140.59 626.51 101.69 720.54 115.29"
            />
            <polygon
              class="cls9-5"
              points="797.77 150.03 783.92 167.78 758.74 148.9 762.51 122.84 797.77 150.03"
            />
            <polygon
              class="cls9-4"
              points="626.51 101.69 610.14 144.37 598.39 154.94 556.83 141.73 626.51 101.69"
            />
            <polygon
              class="cls9-6"
              points="598.39 154.94 548.02 173.83 556.83 141.73 598.39 154.94"
            />
            <polygon
              class="cls9-6"
              points="616.86 175.34 585.79 194.22 598.39 154.94 610.14 144.37 616.86 175.34"
            />
            <polygon
              class="cls9-5"
              points="661.35 179.87 640.9 195.88 616.86 175.34 661.35 179.87"
            />
            <polygon
              class="cls9-4"
              points="783.92 167.78 726.83 213.86 758.74 148.9 783.92 167.78"
            />
            <polygon
              class="cls9-5"
              points="758.74 148.9 726.83 213.86 694.93 151.17 758.74 148.9"
            />
            <polygon
              class="cls9-4"
              points="726.83 213.86 683.18 231.99 694.93 151.17 726.83 213.86"
            />
            <polygon
              class="cls9-6"
              points="694.93 151.17 661.35 179.87 650.44 140.59 694.93 151.17"
            />
            <polygon
              class="cls9-6"
              points="797.77 150.03 793.08 176.03 783.92 167.78 797.77 150.03"
            />
            <polygon
              class="cls9-4"
              points="914.47 208.19 797.77 150.03 811.21 143.24 914.47 208.19"
            />
            <polygon
              class="cls9-6"
              points="954.34 219.9 897.25 231.23 914.47 208.19 928.32 201.78 954.34 219.9"
            />
            <polygon
              class="cls9-5"
              points="914.47 208.19 897.25 231.23 884.66 247.85 861.16 237.28 914.47 208.19"
            />
            <polygon
              class="cls9-4"
              points="1041.65 249.36 972.81 238.79 954.34 219.9 1041.65 249.36"
            />
            <path
              class="cls9-7"
              d="M567.11,377l-9.86-1.5A46.28,46.28,0,0,1,567.11,377Z"
            />
            <path
              class="cls9-8"
              d="M553.23,367.83c-4.73,3.43-14,1.24-14.3-6.93,0,0-.16-8.18,8.88-9.35l4.78.87h0c2,1,4,3.18,4.82,7.58C557.43,360,558,364.39,553.23,367.83Z"
            />
            <path
              class="cls9-7"
              d="M552.59,352.41h0l-4.78-.87A8.52,8.52,0,0,1,552.59,352.41Z"
            />
            <line
              class="cls9-7"
              x1="552.68"
              y1="369.6"
              x2="549.6"
              y2="369.32"
            />
            <line
              class="cls9-7"
              x1="557.25"
              y1="375.45"
              x2="556.34"
              y2="375.32"
            />
            <line
              class="cls9-7"
              x1="591.81"
              y1="380.76"
              x2="591.69"
              y2="380.72"
            />
            <path class="cls9-7" d="M586.39,352.78h0" />
            <line
              class="cls9-7"
              x1="708.63"
              y1="395.4"
              x2="708.41"
              y2="395.26"
            />
            <path
              class="cls9-7"
              d="M633.76,521.26a19.11,19.11,0,0,1-7.8,2.08Z"
            />
            <line
              class="cls9-7"
              x1="709.11"
              y1="410.34"
              x2="719.54"
              y2="414.43"
            />
            <polygon
              class="cls9-6"
              points="650.44 140.59 610.14 144.37 626.51 101.69 650.44 140.59"
            />
            <polygon
              class="cls9-6"
              points="762.51 122.84 758.74 148.9 694.93 151.17 736.49 128.88 762.51 122.84"
            />
            <polygon
              class="cls9-4"
              points="598.39 154.94 585.79 194.22 542.98 199.51 485.05 225.19 522.83 188.93 548.02 173.83 598.39 154.94"
            />
            <polygon
              class="cls9-5"
              points="453.15 225.95 415.79 214.24 435.94 196.86 453.15 225.95"
            />
            <path
              class="cls9-5"
              d="M638.23,261.45l-41.52-31,44.19-34.6C640.84,196.79,637,259.2,638.23,261.45Z"
            />
            <path
              class="cls9-4"
              d="M683.18,232l-44.95,29.46c-1.22-2.25,2.61-64.66,2.67-65.57h0Z"
            />
            <polygon
              class="cls9-3"
              points="1121.79 277.69 945.95 261.45 933.36 245.59 995.48 252.38 972.81 238.79 1041.65 249.36 1121.79 277.69"
            />
            <polygon
              class="cls9-9"
              points="1218 261.46 1121.79 277.69 1212.23 245.59 1218 261.46"
            />
            <polygon
              class="cls9-4"
              points="1236 258.43 1218 261.46 1212.23 245.59 1236 258.43"
            />
            <polygon
              class="cls9-5"
              points="1236 298.84 1236 311.06 1232.17 309.94 1212.15 304.09 1197.79 299.9 1231.61 298.96 1236 298.84"
            />
            <polygon
              class="cls9-6"
              points="683.18 231.99 640.9 195.88 661.35 179.87 683.18 231.99"
            />
            <polygon
              class="cls9-5"
              points="650.44 140.59 616.86 175.34 610.14 144.37 650.44 140.59"
            />
            <polygon
              class="cls9-6"
              points="861.16 237.28 783.92 226.7 793.08 176.03 861.16 237.28"
            />
            <polygon
              class="cls9-5"
              points="793.08 176.03 783.92 226.7 783.92 167.78 793.08 176.03"
            />
            <polygon
              class="cls9-6"
              points="783.92 167.78 783.92 226.7 726.83 213.86 783.92 167.78"
            />
            <polygon
              class="cls9-4"
              points="914.47 208.19 861.16 237.28 793.08 176.03 914.47 208.19"
            />
            <polygon
              class="cls9-5"
              points="914.47 208.19 793.08 176.03 797.77 150.03 914.47 208.19"
            />
            <polygon
              class="cls9-4"
              points="661.35 179.87 616.86 175.34 650.44 140.59 661.35 179.87"
            />
            <polygon
              class="cls9-5"
              points="694.93 151.17 683.18 231.99 661.35 179.87 694.93 151.17"
            />
            <polygon
              class="cls9-5"
              points="933.36 245.59 945.95 261.45 897.25 231.23 954.34 219.9 972.81 238.79 995.48 252.38 933.36 245.59"
            />
            <polygon
              class="cls9-5"
              points="542.98 199.51 523.67 258.43 485.05 225.19 542.98 199.51"
            />
            <polygon
              class="cls9-4"
              points="585.79 194.22 523.67 258.43 542.98 199.51 585.79 194.22"
            />
            <polygon
              class="cls9-8"
              points="189.74 277.69 161.42 283.04 161.42 261.64 189.74 277.69"
            />
            <polygon
              class="cls9-3"
              points="380.57 295.81 189.74 277.69 308.75 245.59 380.57 295.81"
            />
            <polygon
              class="cls9-8"
              points="453.15 225.95 418.73 253.14 380.57 295.81 308.75 245.59 453.15 225.95"
            />
            <path
              class="cls9-10"
              d="M634,521.2l-25.08,12.11-.4-6.75L618,522a19.21,19.21,0,0,0,15.77-.77h0Z"
            />
            <path
              class="cls9-7"
              d="M633.76,521.26a19.11,19.11,0,0,1-7.8,2.08Z"
            />
            <polygon
              class="cls9-7"
              points="749.71 459.2 749.68 459.88 748.17 459.95 749.71 459.2"
            />
            <path
              class="cls9-6"
              d="M640.9,195.88h0l-44.14,34.6-73,28,62-64.21Z"
            />
            <polygon
              class="cls9-3"
              points="638.23 261.45 603.18 312.8 602.66 313.56 602.24 314.17 601.86 313.89 599.92 312.52 523.67 258.43 596.71 230.48 638.23 261.45"
            />
            <polygon
              class="cls9-11"
              points="380.57 295.82 242.15 358.37 242.14 358.37 241.95 358.46 238.19 360.16 231.36 348.54 189.74 277.69 379.32 295.7 379.33 295.7 380.57 295.82"
            />
            <polygon
              class="cls9-3"
              points="238.19 360.16 161.42 283.04 189.74 277.69 231.36 348.54 238.19 360.16"
            />
            <polygon
              class="cls9-3"
              points="500.02 539.55 352.6 604 161 604 161 591.54 163.7 591.54 231.52 566.75 260.84 556.04 298.68 542.2 348.21 524.1 348.21 514.89 373.4 514.89 436.36 491.88 444.76 488.81 471.62 488.81 461.11 493.49 462.22 512.08 467.28 512.87 468.67 535.49 472.11 536.83 481.19 534.13 479.97 513.77 485 513.52 485.09 516.92 481.13 519 481.27 521.27 485.5 521.82 489.08 525.43 489.36 535.69 488.08 536.82 500.02 539.55"
            />
            <polygon
              class="cls9-2"
              points="241.95 358.46 238.19 360.16 161.42 283.04 231.36 348.54 241.46 358 241.9 358.41 241.95 358.46"
            />
            <polygon
              class="cls9-2"
              points="473.57 367.54 473.3 367.65 440.64 358.46 473.57 367.54"
            />
            <path
              class="cls9-3"
              d="M601.85,313.89,564.7,329.43h0a40.65,40.65,0,0,0-11.57,2.09v.88c-1.11.32-1.72.55-1.72.55l.11,2-78,32.61-.27.11-32.66-9.19L370,338.61l-127.84,19.8,138.42-62.55,4.56-1.19,214.79,17.9Z"
            />
            <polygon
              class="cls9-7"
              points="382.25 294.38 385.13 294.62 380.57 295.81 242.15 358.36 242.14 358.36 239.86 358.72 379.32 295.69 379.33 295.69 379.98 295.41 381.57 294.68 381.58 294.68 382.25 294.38"
            />
            <path
              class="cls9-7"
              d="M553.26,334.21l-1.72.72-.12-2s.62-.24,1.73-.55Z"
            />
            <polygon
              class="cls9-7"
              points="604.26 312.89 602.66 313.56 601.86 313.89 599.92 312.52 603.18 312.8 604.26 312.89"
            />
            <polygon
              class="cls9-8"
              points="599.92 312.52 385.13 294.62 523.67 258.43 599.92 312.52"
            />
            <polygon
              class="cls9-11"
              points="523.67 258.43 385.13 294.62 380.57 295.81 381.58 294.68 418.73 253.14 485.05 225.19 523.67 258.43"
            />
            <polygon
              class="cls9-11"
              points="972.81 314.17 929.69 335.92 898.93 336.23 874.91 336.23 602.24 314.17 602.66 313.56 604.26 312.89 603.18 312.8 638.23 261.45 863.87 295.81 972.81 314.17"
            />
            <polygon
              class="cls9-7"
              points="604.26 312.89 602.66 313.56 603.18 312.8 604.26 312.89"
            />
            <path
              class="cls9-12"
              d="M874.91,336.23h-.32v16l-8.8,2.28h0l-4.62-3.13H842.69v9.12L832.91,363l-10.37-2.6H798.19V372l-84.94,22-4.89-.14-.25-7.25-12.51-9.09-2,1.07a13.12,13.12,0,0,0-5.78-2.52c-9.4-2-26.39-5-36.4-3.74a43.13,43.13,0,0,0-11.66-1.08l-.29-2.9s-.4-6.8-23.93-6.41l-.22-3.81s-3.76-6.59-14.7-6.82c-10.62-.22-14,1.35-14.2,1.44a26.31,26.31,0,0,0-3.6-18s-.13-2.27-11.63-3.66a49.18,49.18,0,0,0-8.14-.29h0a45.43,45.43,0,0,0-9.84,1.53v-.88a40.65,40.65,0,0,1,11.57-2.09h0l37.15-15.54.39.28Z"
            />
            <path
              class="cls9-3"
              d="M1236,591.54V604H462.65l142.22-68.72,4.2.23-.14-2.2L634,521.2h0l12.66-3.38c2.2-.69,3.85-2.56,4.91-5.11l4.8-2.32c3.05,4.45,5.94,2.13,5.94,2.13,8.25-2.66,7.93-7.94,7.93-7.94.08-.31.16-.64.23-1L707,485.92v.2l7-.34-1.33-2.6,51.18-24.73-11.07-.71-3.06,1.47.45-10.7-2-11.83,13.88-4.07,25.19,8.44,10.91,3.67,24.35,8.16,20.15,6.73,18.47,6.19,13.43,4.51,24.34,8.16,11.76,3.94,42.81,14.36,7.56,2.53,80.59,27,8.4,2.81,46.29,15.53,30.1,10.09,83.12,27.87Z"
            />
            <polygon
              class="cls9-10"
              points="1202.87 519.37 640.75 556.43 575.97 554.19 1151.34 516.99 1202.87 519.37"
            />
            <polygon
              class="cls9-10"
              points="818.06 510.93 240.83 542.59 207.27 541.43 789.77 508.6 818.06 510.93"
            />
            <polygon
              class="cls9-13"
              points="1151.34 516.99 575.97 554.19 240.83 542.59 818.06 510.93 903.7 518 1105.41 518.27 1118.76 519.07 1117.86 515.45 1151.34 516.99"
            />
            <polygon
              class="cls9-14"
              points="739.17 419.53 732.49 415.68 731.33 415.73 731.07 411.13 726.57 409.5 723.96 404.43 735.6 413.66 739.17 419.53"
            />
            <path
              class="cls9-15"
              d="M798.19,372v11.28l-10.91-1.73H762.09v51.06l-13.92,4.06-1.93-11.43-7.07-5.71-3.57-5.88L724,404.42l-11.43.55a13.26,13.26,0,0,0-3.86-2.09h0l-.25-7.62v-1.4l4.89.14Z"
            />
            <line
              class="cls9-7"
              x1="937.22"
              y1="371.35"
              x2="937.22"
              y2="370.97"
            />
            <line
              class="cls9-7"
              x1="987.06"
              y1="377.53"
              x2="987.06"
              y2="377.01"
            />
            <line
              class="cls9-7"
              x1="436.36"
              y1="406.78"
              x2="435.94"
              y2="406.65"
            />
            <line
              class="cls9-7"
              x1="348.21"
              y1="394.39"
              x2="330.01"
              y2="394.39"
            />
            <polygon
              class="cls9-2"
              points="1212.15 304.09 1090.6 300.89 1090.59 300.89 1070.73 300.37 1054.34 299.94 1121.79 277.69 1197.79 299.9 1212.15 304.09"
            />
            <polygon
              class="cls9-8"
              points="972.81 314.17 863.87 295.81 638.23 261.45 683.18 231.99 698.71 252.52 783.92 226.7 828.41 251.63 972.81 314.17"
            />
            <polygon
              class="cls9-16"
              points="929.69 335.93 921.6 340.01 898.93 338.17 874.91 336.23 898.93 336.23 929.69 335.93"
            />
            <polygon
              class="cls9-3"
              points="1121.79 277.69 1054.34 299.94 937.22 338.57 921.6 340.01 929.69 335.93 972.81 314.17 1033.21 299.38 1121.79 277.69"
            />
            <polygon
              class="cls9-11"
              points="1121.79 277.69 972.81 314.17 828.41 251.63 861.16 237.28 884.66 247.85 897.25 231.23 945.95 261.45 1121.79 277.69"
            />
            <line
              class="cls9-17"
              x1="514.02"
              y1="442.79"
              x2="510.02"
              y2="444.18"
            />
            <polygon
              class="cls9-18"
              points="0 177.93 0 221.36 779 197.19 0 177.93"
            />
            <polygon
              class="cls9-9"
              points="1236 258.43 1236 298.84 1231.61 298.96 1218 261.46 1236 258.43"
            />
            <polygon
              class="cls9-2"
              points="1245.2 304.96 1236 304.72 1236 311.06 1232.17 309.94 1231.9 304.61 1231.61 298.96 1236 300.9 1245.2 304.96"
            />
            <polygon
              class="cls9-4"
              points="1231.61 298.96 1197.79 299.89 1218 261.46 1231.61 298.96"
            />
            <polygon
              class="cls9-5"
              points="1218 261.46 1197.79 299.89 1197.79 299.89 1121.79 277.69 1218 261.46"
            />
            <path
              class="cls9-2"
              d="M553.23,367.83l-.55,1.77-1.92,6.1c-13.72,1.77-23.34,6.67-30.08,12.72a45.51,45.51,0,0,0-10.74,14.47,52.32,52.32,0,0,0-5.06,21V424A47,47,0,0,0,510,444.17l-13.21,4.6-11.34-.49-6.29-.27.32,17.89-5.76,1h11.73v.76c-5.33,0-9.65,4.74-9.65,10.57a11.18,11.18,0,0,0,1.74,6l-5.94,4.53H444.76l-8.4,3.07-63,23H348.21V524l-49.53,18.1L260.84,556l-29.32,10.71L163.7,591.48c82.71-175.47,32.56-162.4,32.56-162.4H0v-118l161.42-28,76.77,77.12,3.76-1.7H242l127.61-19.64,71,19.59,32.68,9.18.27-.11h.15l77.86-31.89,1,16.76a8.6,8.6,0,0,0-4.78-.86c-9,1.17-8.88,9.35-8.88,9.35C539.26,369.07,548.5,371.26,553.23,367.83Z"
            />
            <path
              class="cls9-2"
              d="M1236,591.54l-26.44-8.87-83.12-27.87-30.1-10.09-46.29-15.53-8.4-2.81-80.59-27-7.56-2.53-42.81-14.36-11.76-3.94-24.34-8.16-13.43-4.51-18.47-6.26-20.15-6.76-24.35-8.16L787.28,441l-25.19-8.41V381.55h25.19l10.91,1.73V360.4h24.35l10.37,2.6,9.78-2.54v-9.12h18.47l4.62,3.13h0l8.8-2.28v-16h.32l24,1.94L921.57,340l15.65-1.36L1054.34,300l16.39.43,19.86.52h0l121.55,3.2,20,5.85,3.83,1.12v-6.34l9.2.24,121.62,53.76-.82,89.3-133-5S1197,440.07,1236,591.54Z"
            />
            <polygon
              class="cls9-18"
              points="1366.82 284.84 1366.82 324.17 1245.2 320.96 1236 320.72 1231.9 320.61 1212.15 320.09 1090.6 316.89 1090.59 316.89 1070.73 316.37 1054.34 315.94 1033.21 315.38 932.53 312.72 856.68 310.72 637 304.93 790.84 300.7 897.95 297.75 1085.36 292.59 1104.25 292.07 1116.27 291.74 1128.22 291.41 1136.71 291.18 1140.16 291.08 1171.69 290.21 1211.88 289.11 1222.12 288.82 1236 288.44 1328.5 285.9 1328.51 285.9 1362.42 284.96 1366.82 284.84"
            />
            <polygon
              class="cls9-12"
              points="713.25 394.01 746.24 425.25 536.32 419.53 466.54 475.29 544.27 337.97 585.79 320.6 731.57 389.26 713.25 394.01"
            />
            <polygon
              class="cls9-19"
              points="115.56 568.05 115.56 568.05 115.56 568.05 115.56 568.05 115.67 568.05 115.56 568.05 115.56 568.05"
            />
            <path
              class="cls9-17"
              d="M119.76,542.25a26.09,26.09,0,0,1,3.07,1.21"
            />
            <path class="cls9-17" d="M116.44,541.61a17.94,17.94,0,0,1,2.3.33" />
            <path class="cls9-17" d="M126.53,546a15.48,15.48,0,0,1,2.31,2.41" />
            <path
              class="cls9-17"
              d="M204.9,572.77a3.46,3.46,0,1,0,3.4,3.52h0v-.11a3.49,3.49,0,0,0-3.29-3.51A.11.11,0,0,1,204.9,572.77Z"
            />
            <polygon
              class="cls9-17"
              points="188.11 577.49 191.62 577.49 189.86 572.99 188.11 577.49"
            />
            <path
              class="cls9-17"
              d="M180.09,572.77a3.46,3.46,0,1,0,3.4,3.52h0a3.49,3.49,0,0,0-3.29-3.51Z"
            />
            <polygon
              class="cls9-20"
              points="810.78 141.36 807.7 160.59 798.08 155.77 798.02 155.75 796.94 155.21 796.92 155.34 773.16 146.46 758.51 140.99 753.06 138.95 753.05 138.95 737.28 133.05 737.27 133.05 729.01 129.96 694.79 117.17 679.28 111.37 665.44 105.85 665.44 92.92 810.78 141.36"
            />
            <path
              class="cls9-21"
              d="M760,165.14c-.38.39-.84.79-1.2,1.22A45.2,45.2,0,0,1,737,171a1.84,1.84,0,0,0-.32-.88c-.45-.65-1.21-1-1.71-1.61-.87-1.06-.72-2.61-.52-4a5.62,5.62,0,0,1,.19-1.06A66.77,66.77,0,0,0,751.87,162c3.06-.71,6.18-1.72,8.53-3.75A5.53,5.53,0,0,1,760,165.14Z"
            />
            <path
              class="cls9-22"
              d="M758.76,155.41a1.82,1.82,0,0,0,0,1.13,1.59,1.59,0,0,0,.47.56c.12.09.23.17.34.27a4.37,4.37,0,0,1,.83.89c-2.35,2-5.47,3-8.53,3.75a66.77,66.77,0,0,1-17.21,1.47,3.61,3.61,0,0,1,.75-1.58c.52-.59,1.31-.94,1.67-1.62.51-1-.08-2.14-.59-3.11a6.25,6.25,0,0,1-.91-3.6,3.45,3.45,0,0,1,.24-.89,78.93,78.93,0,0,0,14.42-.17A25,25,0,0,0,761.88,148a5.6,5.6,0,0,1-1.07,4.4c-.45.56-1,1-1.43,1.63A4.75,4.75,0,0,0,758.76,155.41Z"
            />
            <path
              class="cls9-21"
              d="M761.88,148a25,25,0,0,1-11.64,4.53,78.93,78.93,0,0,1-14.42.17c.3-.79.78-1.51,1.16-2.27a4.64,4.64,0,0,0,.54-2.16c4.4,1.33,9.17.31,13.68-.73,2.86-.67,6.53-1.34,9.22-2.57A5.37,5.37,0,0,1,761.88,148Z"
            />
            <path
              class="cls9-21"
              d="M753.05,139a46.58,46.58,0,0,1-16.65-.24,4.66,4.66,0,0,0,.79-.81,6.37,6.37,0,0,0,.09-4.85Z"
            />
            <path
              class="cls9-22"
              d="M760.42,145c-2.69,1.23-6.36,1.9-9.22,2.57-4.51,1-9.28,2.06-13.68.73a2.19,2.19,0,0,0-.36-1.13c-.46-.71-1.33-1.07-1.85-1.73-.88-1.13-.55-2.73-.17-4.11a5.47,5.47,0,0,1,1.09-2.4l.17-.17a46.58,46.58,0,0,0,16.65.24h0l5.45,2a2.64,2.64,0,0,0-.38.88c-.2,1.2,1.11,2,2,2.82A2.13,2.13,0,0,1,760.42,145Z"
            />
            <path
              class="cls9-23"
              d="M735.58,153.61a6.25,6.25,0,0,0,.91,3.6c.51,1,1.1,2.13.59,3.11-.36.68-1.15,1-1.67,1.62a3.61,3.61,0,0,0-.75,1.58c-.07.35-.14.71-.19,1.06-.2,1.36-.35,2.91.52,4,.5.61,1.26,1,1.71,1.61a1.84,1.84,0,0,1,.32.88,2.14,2.14,0,0,1-.64,1.85,2.43,2.43,0,0,1-.25.2l-7.12-3.8V130l8.26,3.09h0a6.37,6.37,0,0,1-.09,4.85,4.66,4.66,0,0,1-.79.81l-.17.17a5.47,5.47,0,0,0-1.09,2.4c-.38,1.38-.71,3,.17,4.11.52.66,1.39,1,1.85,1.73a2.19,2.19,0,0,1,.36,1.13,4.64,4.64,0,0,1-.54,2.16c-.38.76-.86,1.48-1.16,2.27A3.73,3.73,0,0,0,735.58,153.61Z"
            />
            <polygon
              class="cls9-24"
              points="690.82 222.78 696.2 226.17 696.2 251.24 682.36 240.56 682.36 235.95 679.28 111.37 694.79 117.17 696.2 179.04 690.82 176.74 690.82 222.78"
            />
            <polygon
              class="cls9-25"
              points="722.95 242.73 696.2 251.24 696.2 226.17 722.95 242.73"
            />
            <polygon
              class="cls9-26"
              points="702.58 259.81 671.59 237.49 682.36 235.95 682.36 240.56 696.2 251.24 706.77 258.43 702.58 259.81"
            />
            <path
              class="cls9-21"
              d="M749.64,305.73s-6,5.95-14,0a2.23,2.23,0,0,1,0-2.36,29.22,29.22,0,0,0,11.77.73Z"
            />
            <path
              class="cls9-22"
              d="M747.46,304.1a29.22,29.22,0,0,1-11.77-.73,5.54,5.54,0,0,1,.85-1.29s2.25-.77.56-3.07a.86.86,0,0,1-.1-.16,14.59,14.59,0,0,0,4,.46h0Z"
            />
            <path
              class="cls9-21"
              d="M741,299.31a14.59,14.59,0,0,1-4-.46,4.45,4.45,0,0,1-.33-.51c-1.06-1.8-.25-2.46-.25-2.46Z"
            />
            <polygon
              class="cls9-24"
              points="692.73 266.91 692.73 298.09 684.68 300.98 683.61 270.04 692.73 266.91"
            />
            <polygon
              class="cls9-23"
              points="724.23 286.81 720.08 288.3 720.08 283.72 724.23 286.81"
            />
            <polygon
              class="cls9-25"
              points="720.08 283.72 720.08 288.3 692.73 298.09 692.73 266.91 695.99 265.79 720.08 283.72"
            />
            <polygon
              class="cls9-24"
              points="692.73 316.29 692.73 320.42 685.43 322.85 684.98 309.66 692.73 316.29"
            />
            <polygon
              class="cls9-25"
              points="730.21 383.6 730.21 390.66 600.85 452.8 594.3 447.24 561.06 419.02 553.04 412.22 519.33 383.6 507.03 372.06 519.93 365.46 583.09 420.45 602.88 437.69 607 441.27 730.21 383.6"
            />
            <polygon
              class="cls9-26"
              points="519.93 365.46 507.03 372.06 463.97 295.16 472.43 288.24 517.79 363.6 519.93 365.46"
            />
            <polygon
              class="cls9-26"
              points="762.41 340.84 757.72 377.44 730.21 390.66 730.21 383.6 671.59 329 685.43 322.85 692.73 320.42 692.73 316.29 735.42 352.84 762.41 340.84"
            />
            <polygon
              class="cls9-27"
              points="607 441.27 602.88 437.69 555.48 368.22 478.58 295.93 490.88 316.69 519.93 365.46 517.79 363.6 472.43 288.24 557.01 366.68 607 441.27"
            />
            <path
              class="cls9-27"
              d="M671.59,329l-3.83-4.62L557,366.68l50,74.59L730.2,383.61ZM610.07,433.61l-42.88-63.23-2.49-3.67,100.74-38.45,53.06,54.6L716.17,384Z"
            />
            <polygon
              class="cls9-23"
              points="716.17 383.92 610.07 433.58 567.2 370.35 665.44 332.84 716.17 383.92"
            />
            <polygon
              class="cls9-27"
              points="553.04 412.22 541.63 422.04 520.1 385.9 519.33 383.6 553.04 412.22"
            />
            <polygon
              class="cls9-27"
              points="600.85 452.8 545.3 479.5 501.65 393.59 520.1 385.9 541.63 422.04 549.32 428.2 554.96 423.79 587 450.5 594.3 447.24 600.85 452.8"
            />
            <path
              class="cls9-25"
              d="M750.89,398.4l-.69,10-65.93,32.84-1.14-2.31a3,3,0,0,0-3.85,0c-2.31,1.54-6.92,2.31-6.15,6.15l.77,3.08-14.61,6.15s-1.53-14.58,38.47-29.19V414.4l22.72-11.33V414.4Z"
            />
            <path
              class="cls9-27"
              d="M724.87,488.77l-12.52,7.84L673.9,448.16l-.77-3.08c-.77-3.84,3.84-4.61,6.15-6.15a3,3,0,0,1,3.85,0l1.14,2.31Z"
            />
            <path
              class="cls9-27"
              d="M757.76,492.79l-16.15,10c-26.15,17.69-26.92,27.68-26.92,27.68l-13.45-17.18c-.39-8,11.15-16.65,11.15-16.65l12.52-7.87,20.79-9.58Z"
            />
            <path
              class="cls9-27"
              d="M697.76,414.79v10.33c-40,14.61-38.45,29.22-38.45,29.22l-10-12.3c0-1.12,1.22-3,1.88-4h0Z"
            />
            <path
              class="cls9-25"
              d="M757.76,377.44l-1.32,7.69-4.91,2.48-31.07,15.46-22.7,11.33v.39L651.19,438l.42-.59L578.56,472.8l14.61,66.9L597,554.31l3.84,1.1,1.54,8.13-10.76,6.15-2.31-6.15,1.54-5.38-10-38.68h0l-10.79-42.07-8.42,4.2-5.42,2.7-6.92,3.08-4-7.91,55.54-26.7,129.36-62.14Z"
            />
            <polygon
              class="cls9-27"
              points="591.62 569.69 588.54 565.13 587.77 560.46 588.54 555.85 585.47 540.47 569.61 510.1 558.47 488.77 560.47 500.04 560.47 506.1 563.16 513.55 557.78 502.79 558.55 498.94 556.24 484.33 561.66 481.63 580.9 519.46 580.91 519.48 590.85 558.16 589.31 563.54 591.62 569.69"
            />
            <polygon
              class="cls9-23"
              points="580.9 519.46 561.66 481.63 570.09 477.41 580.9 519.46"
            />
            <polygon
              class="cls9-27"
              points="610.84 568.15 592.39 579.69 553.17 505.1 557.78 502.79 563.16 513.55 569.61 510.1 585.47 540.47 588.54 555.85 587.77 560.46 588.54 565.13 591.62 569.69 602.38 563.54 600.85 555.41 597 554.31 593.16 539.7 610.84 568.15"
            />
            <path
              class="cls9-21"
              d="M764.12,268.23l-1.79-.93-.51,9.16L744,283.72l-31.27-21.27,12.83-4.76a7.13,7.13,0,0,0,.88,4.76c.64,1.25,1.08,2.14,1.4,2.78.54,1.1.69,1.42.71,1.49h0c4.67,6.11,26.12,5.34,26.12,5.34a11.19,11.19,0,0,1,1.6-5.49c1.41-2.11.35-3,.07-3.2Z"
            />
            <path
              class="cls9-21"
              d="M756.12,263.28a88.4,88.4,0,0,1-28.36,2c-.32-.64-.76-1.53-1.4-2.78a7.13,7.13,0,0,1-.88-4.76,4.57,4.57,0,0,1,1.42-2.51h0s1.74-.58,1.69-3.76a27,27,0,0,0,11,1.65l8.31,5.14Z"
            />
            <path
              class="cls9-22"
              d="M739.62,253.06a27,27,0,0,1-11-1.65v-.17a11.67,11.67,0,0,0-.14-1.45c-.37-2.17,0-3,.45-3.34Z"
            />
            <polygon
              class="cls9-26"
              points="761.83 276.46 760.79 295.16 743.96 283.72 761.83 276.46"
            />
            <polygon
              class="cls9-26"
              points="859.23 131.37 810.78 141.36 665.44 92.92 710.04 86 859.23 131.37"
            />
            <polygon
              class="cls9-26"
              points="768.97 199.96 767.72 215.96 747.95 205.75 768.97 199.96"
            />
            <path
              class="cls9-21"
              d="M769.68,191l-.71,9-21,5.79-33.32-17.13,21.28-6.09a10.89,10.89,0,0,1-.95,2.41,8.58,8.58,0,0,0-.38,1,10.53,10.53,0,0,0-.45,4.21,1.35,1.35,0,0,0,1.1,1.56,1.23,1.23,0,0,0,.27,0l3,.32c.93.1,1.86.2,2.8.26a30.34,30.34,0,0,0,9.25-.88,13.38,13.38,0,0,0,7-3.77,6.93,6.93,0,0,0,1.36-2.39Z"
            />
            <polygon
              class="cls9-25"
              points="833.85 152.9 822.32 219.03 821.55 224.17 819.94 234.88 812.97 281.43 811.78 289.35 811.46 291.48 810.78 296.05 778.62 310.13 796.92 155.34 798.02 155.75 798.08 155.77 807.7 160.59 833.85 152.9"
            />
            <polygon
              class="cls9-28"
              points="859.23 131.37 856.92 147.51 833.85 152.9 807.7 160.59 810.78 141.36 859.23 131.37"
            />
            <polygon
              class="cls9-26"
              points="898.45 252.87 878.45 328.23 853.07 426.66 844.62 434.35 888.87 269.45 888.88 269.45 892.29 256.71 811.78 289.35 812.97 281.43 867.68 259.25 881.53 253.64 821.55 224.17 822.32 219.03 887.68 250.56 891.52 248.25 898.45 252.87"
            />
            <polygon
              class="cls9-24"
              points="787.54 321.03 786.17 331.3 777.71 325.15 765.41 317.46 760.26 313.63 749.64 305.73 747.46 304.11 741.03 299.32 741.02 299.31 736.42 295.88 724.24 286.81 724.23 286.81 720.08 283.72 695.99 265.79 706.07 262.33 787.54 321.03"
            />
            <path
              class="cls9-25"
              d="M860.76,430.5l-1.54,10-144.57,90s.77-10,26.92-27.68l16.15-10L769.25,379l8.51-53.86,8.46,6.15,21.45-9.3-5.37,33-1.55,9.57h0l-.51,3.13-3.13,18.91-.31,1.85-2,12.64-8.41,51.7-4,24.61Z"
            />
            <polygon
              class="cls9-27"
              points="860.76 430.5 782.33 477.41 786.33 452.8 827.01 428.9 837.5 422.74 839.67 421.47 837.69 428.97 844.62 434.35 853.07 426.66 860.76 430.5"
            />
            <path
              class="cls9-24"
              d="M773.16,219.68l-2.72,51.85-6.32-3.3-7.81-4.83h0l-.12-.08L748,258.24l-8.31-5.14L729,246.49l-6-3.72-26.74-16.56-5.38-3.39v-46l5.38,2.3,18.37,9.49L748,205.79,767.76,216Z"
            />
            <polygon
              class="cls9-25"
              points="729.01 129.96 729.01 169.25 696.2 179.04 694.79 117.17 729.01 129.96"
            />
            <polygon
              class="cls9-24"
              points="796.92 155.34 778.62 310.13 760.79 295.16 761.83 276.46 762.33 267.3 764.12 268.23 770.44 271.53 773.16 219.68 767.72 215.96 768.97 199.96 769.68 190.95 773.16 146.46 796.92 155.34"
            />
            <polygon
              class="cls9-24"
              points="702.58 259.81 683.47 266.08 683.13 255.94 671.59 248.25 671.59 237.49 702.58 259.81"
            />
            <polygon
              class="cls9-25"
              points="827.7 303.62 826.93 313.62 807.62 322 786.17 331.3 787.54 321.03 827.7 303.62"
            />
            <polygon
              class="cls9-25"
              points="867.68 342.07 865.38 353.6 856.92 355.91 797.11 386.61 800.18 367.68 860.76 337.07 867.68 342.07"
            />
            <polygon
              class="cls9-26"
              points="865.21 320.42 863.4 327.17 802.25 354.97 807.62 322 826.93 313.62 827.7 303.62 819.24 297.3 827.01 294.15 865.21 320.42"
            />
            <line
              class="cls9-23"
              x1="811.46"
              y1="291.48"
              x2="810.78"
              y2="290.97"
            />
            <line
              class="cls9-23"
              x1="797.11"
              y1="386.61"
              x2="797.1"
              y2="386.61"
            />
            <polygon
              class="cls9-25"
              points="863.4 327.17 861.24 335.28 861.01 336.13 860.76 337.07 800.18 367.68 800.69 364.55 800.7 364.54 802.25 354.97 863.4 327.17"
            />
            <polygon
              class="cls9-29"
              points="888.87 269.45 844.62 434.35 837.69 428.97 839.67 421.47 856.92 355.91 865.38 353.6 867.68 342.07 860.76 337.07 861.01 336.13 861.24 335.28 863.4 327.17 865.21 320.42 877.68 273.63 888.87 269.45"
            />
            <polygon
              class="cls9-29"
              points="892.29 256.71 888.88 269.45 888.87 269.45 877.68 273.63 827.01 294.15 819.24 297.3 811.46 291.48 811.78 289.35 892.29 256.71"
            />
            <line
              class="cls9-23"
              x1="802.25"
              y1="354.97"
              x2="800.18"
              y2="355.91"
            />
            <line
              class="cls9-23"
              x1="651.17"
              y1="438.02"
              x2="650.83"
              y2="438.19"
            />
            <line
              class="cls9-23"
              x1="698.51"
              y1="414.4"
              x2="697.74"
              y2="414.79"
            />
            <polygon
              class="cls9-24"
              points="777.71 325.15 769.25 378.98 757.72 492.79 745.66 479.19 745.42 478.91 750.21 408.43 750.89 398.4 750.94 398.37 751.53 387.56 756.4 385.13 757.72 377.44 762.41 340.84 763.13 335.19 765.41 317.46 777.71 325.15"
            />
            <path
              class="cls9-26"
              d="M735.92,181.45a2.41,2.41,0,0,1,0,1l-21.27,6.16-18.45-9.53L729,169.29l7.12,3.8c-.27.19-.56.34-.81.54-1.29,1-1.39,2.92-1.39,4.58a2.06,2.06,0,0,0,.5,1.61,4.7,4.7,0,0,0,.69.39A1.81,1.81,0,0,1,735.92,181.45Z"
            />
            <path
              class="cls9-26"
              d="M728.5,249.79a11.67,11.67,0,0,1,.14,1.45l-21.88,7.19h0l-10.56-7.19,26.74-8.51,6,3.72C728.48,246.75,728.13,247.61,728.5,249.79Z"
            />
            <polygon
              class="cls9-29"
              points="881.53 253.64 867.68 259.25 819.94 234.88 821.55 224.17 881.53 253.64"
            />
            <line
              class="cls9-23"
              x1="746.18"
              y1="478.95"
              x2="745.66"
              y2="479.19"
            />
            <line
              class="cls9-23"
              x1="750.94"
              y1="398.38"
              x2="750.89"
              y2="399.17"
            />
            <line
              class="cls9-23"
              x1="751.57"
              y1="386.92"
              x2="751.53"
              y2="387.56"
            />
            <polygon
              class="cls9-27"
              points="751.53 387.56 750.94 398.37 750.89 398.4 720.46 414.4 720.46 403.07 751.53 387.56"
            />
            <line
              class="cls9-23"
              x1="751.57"
              y1="398.04"
              x2="750.94"
              y2="398.38"
            />
            <polygon
              class="cls9-27"
              points="837.5 423.51 827.01 429.67 794.75 401.87 796.8 389.23 837.5 423.51"
            />
            <path
              class="cls9-22"
              d="M759.89,172.94a25.18,25.18,0,0,1-5.75,1.28l-6.67.94c-2,.29-4,.57-6.07.7l-5.27-2.81a2.43,2.43,0,0,0,.25-.2A2.14,2.14,0,0,0,737,171a45.2,45.2,0,0,0,21.74-4.64,1.69,1.69,0,0,0-.46,1.61,3,3,0,0,0,.46.79A6.55,6.55,0,0,1,759.89,172.94Z"
            />
            <path
              class="cls9-26"
              d="M736.76,132.89a1.35,1.35,0,0,1,.19.33v-.09h0v.09a.31.31,0,0,0,.09.11h.37a.24.24,0,0,0,.12-.07.25.25,0,0,0,.08-.12.38.38,0,0,0,0-.15v-.1a.34.34,0,0,0-.1-.16l-.07-.06a.32.32,0,0,0-.19,0h0l.36.27a1.78,1.78,0,0,0-.23-.43.31.31,0,0,0-.09-.11.33.33,0,0,0-.13-.07h-.29l-.08.06a.42.42,0,0,0-.09.16v.1a.31.31,0,0,0,0,.19Z"
            />
            <path
              class="cls9-21"
              d="M758.92,185.21a7,7,0,0,1-1.36,2.4,13.38,13.38,0,0,1-7,3.77,30.34,30.34,0,0,1-9.25.88c-.94-.06-1.87-.16-2.8-.26l-3-.32a1.35,1.35,0,0,1-1.39-1.31,1.23,1.23,0,0,1,0-.27,10.53,10.53,0,0,1,.45-4.21c4.91.67,9.91-.8,14.68-2.25,1.38-.42,2.78-.85,4.14-1.36a22.88,22.88,0,0,0,4.89-2.4l.19.29A5.71,5.71,0,0,1,758.92,185.21Z"
            />
            <path
              class="cls9-22"
              d="M758.31,179.87a22.88,22.88,0,0,1-4.89,2.4c-1.36.51-2.76.94-4.14,1.36-4.77,1.45-9.77,2.92-14.68,2.25a8.58,8.58,0,0,1,.38-1,10.89,10.89,0,0,0,.95-2.41,2.41,2.41,0,0,0,0-1,51.4,51.4,0,0,0,14.16-1,49.66,49.66,0,0,0,7.46-2.15A3.47,3.47,0,0,0,758.31,179.87Z"
            />
            <path
              class="cls9-21"
              d="M759.89,172.94a6.47,6.47,0,0,1-1.61,3.92,2.92,2.92,0,0,0-.7,1,1.35,1.35,0,0,0,0,.49,49.66,49.66,0,0,1-7.46,2.15,51.4,51.4,0,0,1-14.16,1,1.79,1.79,0,0,0-.8-1.28,4.7,4.7,0,0,1-.69-.39,2.06,2.06,0,0,1-.5-1.61c0-1.66.1-3.56,1.39-4.58.25-.2.54-.35.81-.54l5.27,2.81c2-.13,4.06-.41,6.07-.7l6.67-.94A24.82,24.82,0,0,0,759.89,172.94Z"
            />
            <path
              class="cls9-26"
              d="M726.94,255.18a4.57,4.57,0,0,0-1.42,2.51l-12.83,4.76-2.33-1.59Z"
            />
            <line
              class="cls9-23"
              x1="726.19"
              y1="257.44"
              x2="725.52"
              y2="257.69"
            />
            <path class="cls9-23" d="M729,246.45a1.3,1.3,0,0,1,1.21,0" />
            <path class="cls9-23" d="M728.51,266.72h0" />
            <line
              class="cls9-23"
              x1="720.08"
              y1="288.86"
              x2="720.08"
              y2="288.3"
            />
            <path
              class="cls9-21"
              d="M765.41,317.46l-2.28,17.73-26.94,13-43.43-36.7-7.91-6.69-3.22-2.72,3.09-1.11,8-2.88,27.36-9.79,4.15-1.49h0l12.18,9.07s-.81.66.25,2.46l-6.51,2.5-10.07,3.77L741.18,322l18.08-7.61,1-.76Z"
            />
            <polygon
              class="cls9-22"
              points="763.13 335.19 762.41 340.84 735.42 352.84 692.73 316.29 684.98 309.66 684.98 309.65 681.59 306.75 681.59 302.08 684.81 304.8 692.73 311.49 736.19 348.22 763.13 335.19"
            />
            <path
              class="cls9-30"
              d="M760.26,313.61l-1,.76L741.18,322l-21.1-17.37,10.08-3.8,6.51-2.5a4.45,4.45,0,0,0,.33.51.86.86,0,0,0,.1.16c1.69,2.3-.56,3.07-.56,3.07a5.54,5.54,0,0,0-.85,1.29,2.23,2.23,0,0,0,0,2.36c8,5.95,14,0,14,0Z"
            />
            <line
              class="cls9-23"
              x1="736.96"
              y1="298.24"
              x2="736.67"
              y2="298.34"
            />
            <polygon
              class="cls9-25"
              points="718.5 382.83 716.17 383.92 665.44 332.84 567.2 370.35 564.7 366.68 665.44 328.23 718.5 382.83"
            />
            <polygon
              class="cls9-31"
              points="716.17 383.92 610.07 433.58 567.2 370.35 665.44 332.84 716.17 383.92"
            />
            <polygon
              class="cls9-25"
              points="602.88 437.69 583.09 420.45 553.17 374.37 490.88 316.69 478.58 295.93 555.48 368.22 602.88 437.69"
            />
            <polygon
              class="cls9-31"
              points="583.09 420.45 519.93 365.46 490.88 316.69 553.17 374.37 583.09 420.45"
            />
            <polygon
              class="cls9-25"
              points="594.3 447.24 587 450.5 554.96 423.79 561.06 419.02 594.3 447.24"
            />
            <line
              class="cls9-23"
              x1="554.96"
              y1="423.79"
              x2="554.71"
              y2="423.58"
            />
            <polygon
              class="cls9-25"
              points="561.06 419.02 554.96 423.79 549.32 428.2 541.63 422.04 553.04 412.22 561.06 419.02"
            />
            <polygon
              class="cls9-25"
              points="569.61 510.1 563.16 513.55 560.47 506.1 560.47 500.04 558.47 488.77 569.61 510.1"
            />
            <line
              class="cls9-23"
              x1="862.78"
              y1="335.28"
              x2="802.24"
              y2="364.54"
            />
            <polygon
              class="cls9-26"
              points="827.7 303.62 787.54 321.03 706.07 262.33 710.36 260.86 712.69 262.45 743.96 283.72 760.79 295.16 778.62 310.13 810.78 296.05 811.46 291.48 819.24 297.3 827.7 303.62"
            />
            <path
              class="cls9-22"
              d="M728.65,251.41c.05,3.18-1.69,3.76-1.69,3.76h0l-16.58,5.68-4.29,1.47L696,265.78l-3.24,1.13L683.65,270l-10.52,3.57c.77-2.31-1.54-3.65-1.54-3.65l11.88-3.89h0l19.1-6.27,4.19-1.38h0l21.87-7.19A1.1,1.1,0,0,1,728.65,251.41Z"
            />
            <line
              class="cls9-23"
              x1="728.65"
              y1="251.24"
              x2="728.64"
              y2="251.24"
            />
            <path
              class="cls9-32"
              d="M671.59,270s-2-2.11-2.53,0-23.81,6.48-23.81,6.48-6.7.43-4.79,7.26c0,0,2.26,3.93,7.47,0s22.89-8.55,22.89-8.55,1.54,1.54,2.31-1.54"
            />
            <path
              class="cls9-33"
              d="M760.43,144.2h-.2l-.18.08-.15.12h0l.15-.11h0l.18-.08h.2a.59.59,0,0,0-.29.07.55.55,0,0,0-.24.15.51.51,0,0,0-.16.25.45.45,0,0,0-.06.28v.2a.63.63,0,0,0,.2.33l.15.12a.75.75,0,0,0,.38.1h.2a.78.78,0,0,0,.15-.1l.15-.12h0l-.15.12h0l-.18.07h-.2a.56.56,0,0,0,.38-.07.61.61,0,0,0,.24-.15.56.56,0,0,0,.16-.24.58.58,0,0,0,.06-.29v-.2a.63.63,0,0,0-.2-.33l-.15-.12a.75.75,0,0,0-.38-.1Z"
            />
            <path
              class="cls9-33"
              d="M737.76,147.53l-.3-.08a.51.51,0,0,0-.3,0,.62.62,0,0,0-.27.09.87.87,0,0,0-.35.45v.2a.73.73,0,0,0,.11.38l.11.15a.84.84,0,0,0,.33.19l.3.09a.51.51,0,0,0,.3,0,.44.44,0,0,0,.27-.09.67.67,0,0,0,.22-.19.58.58,0,0,0,.13-.26v-.2a.7.7,0,0,0-.11-.37l-.11-.16a.92.92,0,0,0-.33-.19Z"
            />
            <path
              class="cls9-33"
              d="M735.82,151.93h-.2a.46.46,0,0,0-.28.06.51.51,0,0,0-.25.16.61.61,0,0,0-.15.24.59.59,0,0,0-.07.29v.2a.92.92,0,0,0,.19.33l.16.12a.79.79,0,0,0,.37.1h.2a.59.59,0,0,0,.29-.07.61.61,0,0,0,.24-.15.64.64,0,0,0,.15-.25.55.55,0,0,0,.07-.28v-.2a.88.88,0,0,0-.19-.34l-.15-.11a.75.75,0,0,0-.38-.1Z"
            />
            <path
              class="cls9-33"
              d="M734.66,162.73h-.17a.55.55,0,0,0-.28.07.64.64,0,0,0-.25.15.61.61,0,0,0-.15.24.59.59,0,0,0-.07.29v.2a.84.84,0,0,0,.19.33l.16.12a.69.69,0,0,0,.37.1h.17a.59.59,0,0,0,.29-.07.55.55,0,0,0,.24-.15.56.56,0,0,0,.16-.24.49.49,0,0,0,.06-.29v-.2a.63.63,0,0,0-.2-.33l-.15-.12a.75.75,0,0,0-.38-.1Z"
            />
            <path
              class="cls9-22"
              d="M756.24,266.61a11.19,11.19,0,0,0-1.6,5.49s-21.45.77-26.12-5.34h0s-.17-.39-.71-1.49a88.43,88.43,0,0,0,28.32-1.95l.12.08h0C756.59,263.61,757.65,264.49,756.24,266.61Z"
            />
            <path
              class="cls9-33"
              d="M758,262.06l-2,.5a.76.76,0,0,0-.53.92.78.78,0,0,0,.93.52c.68-.15,1.36-.32,2-.5a.75.75,0,1,0-.28-1.47.53.53,0,0,0-.12,0Z"
            />
            <path
              class="cls9-33"
              d="M727.76,264.48a14.75,14.75,0,0,1-1.56-.16.79.79,0,0,0-.54.22.77.77,0,0,0,0,1.06l.16.12a.72.72,0,0,0,.38.1c.51.06,1,.12,1.56.16a.75.75,0,0,0,.75-.75.79.79,0,0,0-.22-.53l-.16-.12a.79.79,0,0,0-.37-.1Z"
            />
            <path
              class="cls9-33"
              d="M726.76,256.61a102.69,102.69,0,0,0,11.82,2c3.71.38,7.93,1,11.57-.06.92-.28.53-1.73-.4-1.45a16.31,16.31,0,0,1-5.61.43c-1.86-.09-3.71-.23-5.56-.42a98.69,98.69,0,0,1-11.45-1.9c-.94-.21-1.34,1.24-.4,1.45Z"
            />
            <path
              class="cls9-33"
              d="M741,253h0l-.08-.18h0v-.2h0v-.2h0l.08-.18h0l.12-.15-.05,0,.15-.12a.24.24,0,0,1-.11.06l.18-.07h.07c-.57.06-1.13.12-1.7.15a.79.79,0,0,0-.53.22.75.75,0,0,0-.22.53.77.77,0,0,0,.75.75,12.81,12.81,0,0,0,1.39-.12,2.17,2.17,0,0,0,.87-.2,1,1,0,0,0,.39-.38.85.85,0,0,0,0-.87.76.76,0,0,0-1-.27.78.78,0,0,0-.27,1Z"
            />
            <path
              class="cls9-33"
              d="M728.65,250.66h0a.49.49,0,0,0-.29.06.56.56,0,0,0-.24.16.55.55,0,0,0-.15.24.59.59,0,0,0-.07.29v.2a.84.84,0,0,0,.19.33l.15.11a.73.73,0,0,0,.38.11h0a.59.59,0,0,0,.29-.07.61.61,0,0,0,.24-.15.82.82,0,0,0,.16-.25.67.67,0,0,0,.06-.28v-.2a.69.69,0,0,0-.19-.33l-.15-.12a.72.72,0,0,0-.38-.1Z"
            />
            <path
              class="cls9-33"
              d="M737.2,298.13a1.46,1.46,0,0,1-.39-.12.76.76,0,0,0-.3,0,.66.66,0,0,0-.28.09.48.48,0,0,0-.21.19.5.5,0,0,0-.13.26v.2a.75.75,0,0,0,.1.38l.12.15a.84.84,0,0,0,.33.19l.39.12a.76.76,0,0,0,.3,0,.71.71,0,0,0,.28-.09.48.48,0,0,0,.21-.19.5.5,0,0,0,.13-.26v-.2a.75.75,0,0,0-.1-.38l-.12-.15A.69.69,0,0,0,737.2,298.13Z"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default Fcomunicacion;
