import "./acerca.css";
import "../inicio/inicio.css";
import { ReactComponent as Cincuenta } from "../../assets/001Cincuenta.svg";
import { ReactComponent as Muvi } from "../../assets/001LogoMuvi.svg";
import { ReactComponent as LogoPoli } from "../../assets/001LogoPoli.svg";

import fie from "../../assets/logos/fie.svg";
import justinmind from "../../assets/logos/justinmind.svg";
import sketchup from "../../assets/logos/sketchup.svg";
import tutorias from "../../assets/logos/tutorias.svg";
import canva from "../../assets/logos/canva.svg";
import genially from "../../assets/logos/genially.svg";
import msa from "../../assets/logos/msa-3d.svg";
import steam from "../../assets/logos/steam.svg";
import ilustrador from "../../assets/logos/ilustrador.svg";
import unity from "../../assets/logos/unity.svg";
import gamificacion from "../../assets/logos/gamificacion.svg";

const Acercade = () => {
  return (
    <div className="content-inicio">
      <div className="logo-Poli">
        <LogoPoli />
      </div>
      <div className="header">
        <h1 className="title-h1">ACERCA DE </h1>
      </div>
      <div class="container-acerca">
        <div class="card-container">
          <div class="card">
            <img class="icon" src={fie} alt="Rowing icon" />
            <div class="text">
              <div class="name">FACULTAD DE INFORMATICA Y ELECTRONICA</div>
              <div class="jurisdiction">
                Producto realizado en la Facultad de Informática y Electrónica
                como parte de un proyecto de investigación en el tema de diseño
                3D y uso de tegnologías emergentes
              </div>
            </div>
          </div>

          <div class="card">
            <img class="icon" src={justinmind} alt="Rowing icon" />
            <div class="text">
              <div class="name">JUSTINMIND</div>
              <div class="jurisdiction">Para diseños e escenas</div>
            </div>
          </div>

          <div class="card">
            <img class="icon" src={sketchup} alt="Rowing icon" />
            <div class="text">
              <div class="name">SKETCHUP</div>
              <div class="jurisdiction">Para el modelado 3D</div>
            </div>
          </div>

          <div class="card">
            <img class="icon" src={tutorias} alt="Rowing icon" />
            <div class="text">
              <div class="name">CENTRO DE TUTORIAS DE LA FIE</div>
              <div class="jurisdiction"></div>
            </div>
          </div>

          <div class="card">
            <img class="icon" src={canva} alt="Rowing icon" />
            <div class="text">
              <div class="name">CANVA</div>
              <div class="jurisdiction">Para el prototipado</div>
            </div>
          </div>

          <div class="card">
            <img class="icon" src={genially} alt="Rowing icon" />
            <div class="text">
              <div class="name">GENEALLY</div>
              <div class="jurisdiction">
                Para diseño de páginas Web y navegación
              </div>
            </div>
          </div>

          <div class="card">
            <img class="icon" src={msa} alt="Rowing icon" />
            <div class="text">
              <div class="name">GRUPO DE INVESTIGACION MSA-3D</div>
              <div class="jurisdiction"></div>
            </div>
          </div>

          <div class="card">
            <img class="icon" src={steam} alt="Rowing icon" />
            <div class="text">
              <div class="name">STEAM</div>
              <div class="jurisdiction">
                Ciencia, Tegnología, Ingeniería, Arte y Matemáticas para el
                proceso cogmitivo 3D
              </div>
            </div>
          </div>

          <div class="card">
            <img class="icon" src={ilustrador} alt="Rowing icon" />
            <div class="text">
              <div class="name">ADOBE ILUSTRADOR</div>
              <div class="jurisdiction">
                Para el diseño de marca, señalética y pestañas del museo virtual
              </div>
            </div>
          </div>

          <div class="card">
            <img class="icon" src={unity} alt="Rowing icon" />
            <div class="text">
              <div class="name">UNITY 3D</div>
              <div class="jurisdiction">
                Para crear los ambientes tridimencionales
              </div>
            </div>
          </div>

          <div class="card">
            <img class="icon" src={gamificacion} alt="Rowing icon" />
            <div class="text">
              <div class="name">GAMIFICACIÓN</div>
              <div class="jurisdiction">
                Técnica para generar conocimiento basado en actividades lúdicas
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <Cincuenta />
        <div className="content-regresar">
          <a href="/paseo" className="btn-regresar buttonR">
            <span>REGRESAR</span>
          </a>
        </div>
        <Muvi />
      </div>
    </div>
  );
};

export default Acercade;
