import "./pantalla3.css";
import "../inicio/inicio.css";
import { ReactComponent as Cincuenta } from "../../assets/001Cincuenta.svg";
import { ReactComponent as Muvi } from "../../assets/001LogoMuvi.svg";
import { ReactComponent as LogoPoli } from "../../assets/001LogoPoli.svg";

import PaseoVirtual from "../Pantalla3/PaseoVirtual";

const TerceraPantalla = () => {
  return (
    <div className="content-inicio">
      <a href="/paseo-sectores" className="btn-paseo-sectores button">
        <span>PASEO POR SECTORES</span>
      </a>
      <a href="/paseo-libre" className="btn-paseo-campus button">
        <span>PASEO CAMPUS</span>
      </a>
      <div className="logo-Poli">
        <LogoPoli />
      </div>
      <div className="header">
        <h1 className="title-h1">PASEO VIRTUAL DE LA ESPOCH</h1>
        <div className="content-nav">
          <ul>
            <li>
              <a href="/qienes-somos">QUIENES SOMOS</a>
            </li>
            <li>
              <a href="/acerca-de">ACERCA DE</a>
            </li>
            <li>
              <a href="/ayuda-en-linea">AYUDA EN LÍNEA</a>
            </li>
          </ul>
        </div>
      </div>
      <PaseoVirtual />
      <div className="footer">
        <Cincuenta />
        <div className="content-regresar">
          <a href="/paseo-museo" className="btn-regresar buttonR">
            <span>REGRESAR</span>
          </a>
        </div>
        <Muvi />
      </div>
    </div>
  );
};

export default TerceraPantalla;
