import "./computacion.css";

const Fcomputacion = () => {
  return (
    <>
      <svg viewBox="0 0 1367 604">
        <defs>
          <linearGradient
            id="Degradado_sin_nombre"
            y1="244.22"
            x2="1367"
            y2="244.22"
            gradientTransform="matrix(1, 0, 0, -1, 0, 439.22)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#d8f0fe" />
            <stop offset="0.4" stop-color="#d6effe" />
            <stop offset="0.58" stop-color="#cfecfe" />
            <stop offset="0.71" stop-color="#c2e7fd" />
            <stop offset="0.83" stop-color="#b0e0fd" />
            <stop offset="0.92" stop-color="#99d6fd" />
            <stop offset="1" stop-color="#81cdfc" />
          </linearGradient>
          <linearGradient
            id="Degradado_sin_nombre_5"
            x1="507.38"
            y1="44.15"
            x2="501.38"
            y2="27.68"
            gradientTransform="matrix(1, 0, 0, -1, 0, 439.22)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#f82326" stop-opacity="0.99" />
            <stop offset="1" stop-color="#322f33" />
          </linearGradient>
          <linearGradient
            id="Degradado_sin_nombre_5-2"
            x1="507.38"
            y1="25.9"
            x2="501.38"
            y2="9.41"
            xlinkHref="#Degradado_sin_nombre_5"
          />
          <linearGradient
            id="Degradado_sin_nombre_5-3"
            x1="507.38"
            y1="7.63"
            x2="501.38"
            y2="-8.84"
            xlinkHref="#Degradado_sin_nombre_5"
          />
          <linearGradient
            id="Degradado_sin_nombre_5-4"
            x1="507.38"
            y1="-10.64"
            x2="501.38"
            y2="-27.1"
            xlinkHref="#Degradado_sin_nombre_5"
          />
        </defs>
        <g id="Capa_2" data-name="Capa 2">
          <g id="Capa_1-2" data-name="Capa 1">
            <rect class="cls8-1" width="1367" height="390" />
            <polyline
              class="cls8-2"
              points="169.01 591.03 166.23 604 133.53 602.96 0 422 232 425.62 169.01 591.03"
            />
            <polyline
              class="cls8-2"
              points="1366 448.02 1236 604 1236 591.54 1184.9 459.95 1212.23 428.76 1366 448.02"
            />
            <polygon
              class="cls8-2"
              points="1366 448.02 1367 603 1236 604 1366 448.02"
            />
            <polygon
              class="cls8-2"
              points="134.81 603 0 604 0 429.14 5.27 429.14 134.81 603"
            />
            <polygon
              class="cls8-3"
              points="469.02 603 352.6 603 450.1 488.43 536.32 419.53 769.04 425.22 824 444.59 469.02 603"
            />
            <polygon
              class="cls8-4"
              points="640.9 195.88 585.79 194.22 616.86 175.34 640.9 195.88"
            />
            <polygon
              class="cls8-5"
              points="556.83 141.73 548.02 173.83 522.83 188.93 503.04 168.49 556.83 141.73"
            />
            <polygon
              class="cls8-6"
              points="522.83 188.93 453.57 193.09 503.04 168.49 522.83 188.93"
            />
            <polygon
              class="cls8-6"
              points="453.15 225.95 308.75 245.59 349.47 231.61 415.79 214.24 453.15 225.95"
            />
            <polygon
              class="cls8-4"
              points="522.83 188.93 453.15 225.95 435.94 196.86 453.57 193.09 522.83 188.93"
            />
            <polygon
              class="cls8-5"
              points="522.83 188.93 485.05 225.19 418.73 253.14 453.15 225.95 522.83 188.93"
            />
            <polygon
              class="cls8-5"
              points="783.92 226.7 698.71 252.52 683.18 231.99 726.83 213.86 783.92 226.7"
            />
            <polygon
              class="cls8-4"
              points="861.16 237.28 828.41 251.63 783.92 226.7 861.16 237.28"
            />
            <polygon
              class="cls8-4"
              points="736.49 128.88 694.93 151.17 720.54 115.29 736.49 128.88"
            />
            <polygon
              class="cls8-5"
              points="720.54 115.29 694.93 151.17 650.44 140.59 626.51 101.69 720.54 115.29"
            />
            <polygon
              class="cls8-5"
              points="797.77 150.03 783.92 167.78 758.74 148.9 762.51 122.84 797.77 150.03"
            />
            <polygon
              class="cls8-4"
              points="626.51 101.69 610.14 144.37 598.39 154.94 556.83 141.73 626.51 101.69"
            />
            <polygon
              class="cls8-6"
              points="598.39 154.94 548.02 173.83 556.83 141.73 598.39 154.94"
            />
            <polygon
              class="cls8-6"
              points="616.86 175.34 585.79 194.22 598.39 154.94 610.14 144.37 616.86 175.34"
            />
            <polygon
              class="cls8-5"
              points="661.35 179.87 640.9 195.88 616.86 175.34 661.35 179.87"
            />
            <polygon
              class="cls8-4"
              points="783.92 167.78 726.83 213.86 758.74 148.9 783.92 167.78"
            />
            <polygon
              class="cls8-5"
              points="758.74 148.9 726.83 213.86 694.93 151.17 758.74 148.9"
            />
            <polygon
              class="cls8-4"
              points="726.83 213.86 683.18 231.99 694.93 151.17 726.83 213.86"
            />
            <polygon
              class="cls8-6"
              points="694.93 151.17 661.35 179.87 650.44 140.59 694.93 151.17"
            />
            <polygon
              class="cls8-6"
              points="797.77 150.03 793.08 176.03 783.92 167.78 797.77 150.03"
            />
            <polygon
              class="cls8-4"
              points="914.47 208.19 797.77 150.03 811.21 143.24 914.47 208.19"
            />
            <polygon
              class="cls8-6"
              points="954.34 219.9 897.25 231.23 914.47 208.19 928.32 201.78 954.34 219.9"
            />
            <polygon
              class="cls8-5"
              points="914.47 208.19 897.25 231.23 884.66 247.85 861.16 237.28 914.47 208.19"
            />
            <polygon
              class="cls8-4"
              points="1041.65 249.36 972.81 238.79 954.34 219.9 1041.65 249.36"
            />
            <path
              class="cls8-7"
              d="M567.11,377l-9.86-1.5A46.28,46.28,0,0,1,567.11,377Z"
            />
            <path
              class="cls8-8"
              d="M553.26,367.83c-4.73,3.43-14,1.24-14.3-6.93,0,0-.16-8.18,8.88-9.35l4.78.87h0c2,1,4,3.18,4.82,7.58C557.43,360,558,364.39,553.26,367.83Z"
            />
            <path
              class="cls8-7"
              d="M552.59,352.41h0l-4.78-.87A8.52,8.52,0,0,1,552.59,352.41Z"
            />
            <line
              class="cls8-7"
              x1="552.68"
              y1="369.6"
              x2="549.6"
              y2="369.32"
            />
            <line
              class="cls8-7"
              x1="557.25"
              y1="375.45"
              x2="556.34"
              y2="375.32"
            />
            <line
              class="cls8-7"
              x1="591.81"
              y1="380.76"
              x2="591.69"
              y2="380.72"
            />
            <path class="cls8-7" d="M586.39,352.78h0" />
            <line
              class="cls8-7"
              x1="708.63"
              y1="395.4"
              x2="708.41"
              y2="395.26"
            />
            <path
              class="cls8-7"
              d="M633.79,521.26a19.11,19.11,0,0,1-7.8,2.08Z"
            />
            <line
              class="cls8-7"
              x1="709.11"
              y1="410.34"
              x2="719.54"
              y2="414.43"
            />
            <polygon
              class="cls8-6"
              points="650.44 140.59 610.14 144.37 626.51 101.69 650.44 140.59"
            />
            <polygon
              class="cls8-6"
              points="762.51 122.84 758.74 148.9 694.93 151.17 736.49 128.88 762.51 122.84"
            />
            <polygon
              class="cls8-4"
              points="598.39 154.94 585.79 194.22 542.98 199.51 485.05 225.19 522.83 188.93 548.02 173.83 598.39 154.94"
            />
            <polygon
              class="cls8-5"
              points="453.15 225.95 415.79 214.24 435.94 196.86 453.15 225.95"
            />
            <path
              class="cls8-5"
              d="M638.26,261.45l-41.52-31,44.19-34.6C640.84,196.79,637,259.2,638.26,261.45Z"
            />
            <path
              class="cls8-4"
              d="M683.18,232l-44.92,29.46c-1.22-2.25,2.61-64.66,2.67-65.57h0Z"
            />
            <polygon
              class="cls8-3"
              points="1121.79 277.69 945.95 261.45 933.36 245.59 995.48 252.38 972.81 238.79 1041.65 249.36 1121.79 277.69"
            />
            <polygon
              class="cls8-9"
              points="1218 261.46 1121.79 277.69 1212.23 245.59 1218 261.46"
            />
            <polygon
              class="cls8-4"
              points="1236 258.43 1218 261.46 1212.23 245.59 1236 258.43"
            />
            <polygon
              class="cls8-5"
              points="1236 298.84 1236 311.06 1232.17 309.94 1212.15 304.09 1197.79 299.9 1231.61 298.96 1236 298.84"
            />
            <polygon
              class="cls8-6"
              points="683.18 231.99 640.9 195.88 661.35 179.87 683.18 231.99"
            />
            <polygon
              class="cls8-5"
              points="650.44 140.59 616.86 175.34 610.14 144.37 650.44 140.59"
            />
            <polygon
              class="cls8-6"
              points="861.16 237.28 783.92 226.7 793.08 176.03 861.16 237.28"
            />
            <polygon
              class="cls8-5"
              points="793.08 176.03 783.92 226.7 783.92 167.78 793.08 176.03"
            />
            <polygon
              class="cls8-6"
              points="783.92 167.78 783.92 226.7 726.83 213.86 783.92 167.78"
            />
            <polygon
              class="cls8-4"
              points="914.47 208.19 861.16 237.28 793.08 176.03 914.47 208.19"
            />
            <polygon
              class="cls8-5"
              points="914.47 208.19 793.08 176.03 797.77 150.03 914.47 208.19"
            />
            <polygon
              class="cls8-4"
              points="661.35 179.87 616.86 175.34 650.44 140.59 661.35 179.87"
            />
            <polygon
              class="cls8-5"
              points="694.93 151.17 683.18 231.99 661.35 179.87 694.93 151.17"
            />
            <polygon
              class="cls8-5"
              points="933.36 245.59 945.95 261.45 897.25 231.23 954.34 219.9 972.81 238.79 995.48 252.38 933.36 245.59"
            />
            <polygon
              class="cls8-5"
              points="542.98 199.51 523.67 258.43 485.05 225.19 542.98 199.51"
            />
            <polygon
              class="cls8-4"
              points="585.79 194.22 523.67 258.43 542.98 199.51 585.79 194.22"
            />
            <polygon
              class="cls8-8"
              points="189.74 277.69 161.42 283.04 161.42 261.64 189.74 277.69"
            />
            <polygon
              class="cls8-3"
              points="380.57 295.81 189.74 277.69 308.75 245.59 380.57 295.81"
            />
            <polygon
              class="cls8-8"
              points="453.15 225.95 418.73 253.14 380.57 295.81 308.75 245.59 453.15 225.95"
            />
            <path
              class="cls8-10"
              d="M634,521.2l-25.08,12.11-.4-6.75L618,522a19.21,19.21,0,0,0,15.77-.77h0Z"
            />
            <path
              class="cls8-7"
              d="M633.79,521.26a19.11,19.11,0,0,1-7.8,2.08Z"
            />
            <polygon
              class="cls8-7"
              points="749.71 459.2 749.68 459.88 748.17 459.95 749.71 459.2"
            />
            <path
              class="cls8-6"
              d="M640.9,195.88h0l-44.19,34.6-73,28,62.12-64.21Z"
            />
            <polygon
              class="cls8-3"
              points="638.23 261.45 603.18 312.8 602.66 313.56 602.24 314.17 601.86 313.89 599.92 312.52 523.67 258.43 596.71 230.48 638.23 261.45"
            />
            <polygon
              class="cls8-11"
              points="380.57 295.82 242.15 358.37 242.14 358.37 241.95 358.46 238.19 360.16 231.36 348.54 189.74 277.69 379.32 295.7 379.33 295.7 380.57 295.82"
            />
            <polygon
              class="cls8-3"
              points="238.19 360.16 161.42 283.04 189.74 277.69 231.36 348.54 238.19 360.16"
            />
            <polygon
              class="cls8-3"
              points="500.02 539.55 352.6 604 161 604 161 591.54 163.7 591.54 231.52 566.75 260.84 556.04 298.68 542.2 348.21 524.1 348.21 514.89 373.4 514.89 436.36 491.88 444.76 488.81 471.62 488.81 461.11 493.49 462.22 512.08 467.28 512.87 468.67 535.49 472.11 536.83 481.19 534.13 479.97 513.77 485 513.52 485.09 516.92 481.13 519 481.27 521.27 485.5 521.82 489.08 525.43 489.36 535.69 488.08 536.82 500.02 539.55"
            />
            <polygon
              class="cls8-2"
              points="241.95 358.46 238.19 360.16 161.42 283.04 231.36 348.54 241.46 358 241.9 358.41 241.95 358.46"
            />
            <polygon
              class="cls8-2"
              points="473.57 367.54 473.3 367.65 440.64 358.46 473.57 367.54"
            />
            <path
              class="cls8-3"
              d="M601.85,313.89,564.7,329.43h0a40.65,40.65,0,0,0-11.57,2.09v.88c-1.11.32-1.72.55-1.72.55l.11,2-78,32.61-.26.05-32.66-9.19L370,338.61l-127.84,19.8,138.42-62.55,4.56-1.19,214.79,17.9Z"
            />
            <polygon
              class="cls8-7"
              points="382.25 294.38 385.13 294.62 380.57 295.81 242.15 358.36 242.14 358.36 239.86 358.72 379.32 295.69 379.33 295.69 379.98 295.41 381.57 294.68 381.58 294.68 382.25 294.38"
            />
            <path
              class="cls8-7"
              d="M553.26,334.21l-1.72.72-.12-2s.62-.24,1.73-.55Z"
            />
            <polygon
              class="cls8-7"
              points="604.26 312.89 602.66 313.56 601.86 313.89 599.92 312.52 603.18 312.8 604.26 312.89"
            />
            <polygon
              class="cls8-8"
              points="599.92 312.52 385.13 294.62 523.67 258.43 599.92 312.52"
            />
            <polygon
              class="cls8-11"
              points="523.67 258.43 385.13 294.62 380.57 295.81 381.58 294.68 418.73 253.14 485.05 225.19 523.67 258.43"
            />
            <polygon
              class="cls8-11"
              points="972.81 314.17 929.69 335.92 898.93 336.23 874.91 336.23 602.24 314.17 602.66 313.56 604.26 312.89 603.18 312.8 638.23 261.45 863.87 295.81 972.81 314.17"
            />
            <polygon
              class="cls8-7"
              points="604.26 312.89 602.66 313.56 603.18 312.8 604.26 312.89"
            />
            <path
              class="cls8-12"
              d="M874.91,336.23h-.32v16l-8.8,2.28h0l-4.62-3.13H842.69v9.12L832.91,363l-10.37-2.6H798.19V372l-84.93,22-4.89-.14-.25-7.25-12.51-9.09-2,1.07a13.12,13.12,0,0,0-5.78-2.52c-9.4-2-26.39-5-36.4-3.74a43.15,43.15,0,0,0-11.72-1l-.29-2.9s-.4-6.8-23.93-6.41l-.22-3.81s-3.76-6.59-14.7-6.82c-10.62-.22-14,1.35-14.2,1.44a26.31,26.31,0,0,0-3.6-18s-.13-2.27-11.63-3.66a49.18,49.18,0,0,0-8.14-.29h0a45.43,45.43,0,0,0-9.84,1.53v-.88a40.65,40.65,0,0,1,11.57-2.09h0l37.15-15.54.39.28Z"
            />
            <path
              class="cls8-3"
              d="M1236,591.54V604H462.65l142.22-68.72,4.2.23-.14-2.2L634,521.2h0l12.66-3.38c2.2-.69,3.85-2.56,4.91-5.11l4.8-2.32c3.05,4.45,5.94,2.13,5.94,2.13,8.26-2.67,7.93-7.91,7.93-7.91.08-.31.16-.64.23-1L707,486v.2l7-.34-1.33-2.6,51.18-24.73-11.07-.71-3.1,1.48.45-10.7-2-11.83,13.92-4.11,25.17,8.45,10.91,3.67,24.35,8.16,20.17,6.72,18.47,6.19,13.43,4.51,24.34,8.16,11.76,3.94,42.81,14.36,7.56,2.53,80.59,27,8.4,2.81,46.29,15.53,30.1,10.09,83.12,27.87Z"
            />
            <polygon
              class="cls8-10"
              points="1202.87 519.37 640.75 556.43 575.97 554.19 1151.34 516.99 1202.87 519.37"
            />
            <polygon
              class="cls8-10"
              points="818.06 510.93 240.83 542.59 207.27 541.43 789.77 508.6 818.06 510.93"
            />
            <polygon
              class="cls8-13"
              points="1151.34 516.99 575.97 554.19 240.83 542.59 818.06 510.93 903.7 518 1105.41 518.27 1118.76 519.07 1117.86 515.45 1151.34 516.99"
            />
            <polygon
              class="cls8-14"
              points="739.17 419.53 732.49 415.68 731.33 415.73 731.07 411.13 726.57 409.5 723.96 404.43 735.6 413.66 739.17 419.53"
            />
            <path
              class="cls8-15"
              d="M798.19,372v11.28l-10.93-1.73H762.09v51.06l-13.92,4.06-1.91-11.42-7.07-5.71-3.57-5.88L724,404.43l-11.43.55a13.26,13.26,0,0,0-3.86-2.09h0l-.25-7.62v-1.4l4.89.14Z"
            />
            <line
              class="cls8-7"
              x1="937.22"
              y1="371.35"
              x2="937.22"
              y2="370.97"
            />
            <line
              class="cls8-7"
              x1="987.06"
              y1="377.53"
              x2="987.06"
              y2="377.01"
            />
            <line
              class="cls8-7"
              x1="436.36"
              y1="406.78"
              x2="435.94"
              y2="406.65"
            />
            <line
              class="cls8-7"
              x1="348.21"
              y1="394.39"
              x2="330.01"
              y2="394.39"
            />
            <polygon
              class="cls8-2"
              points="1212.15 304.09 1090.6 300.89 1090.59 300.89 1070.73 300.37 1054.34 299.94 1121.79 277.69 1197.79 299.9 1212.15 304.09"
            />
            <polygon
              class="cls8-8"
              points="972.81 314.17 863.87 295.81 638.23 261.45 683.18 231.99 698.71 252.52 783.92 226.7 828.41 251.63 972.81 314.17"
            />
            <polygon
              class="cls8-16"
              points="929.69 335.93 921.6 340.01 898.93 338.17 874.91 336.23 898.93 336.23 929.69 335.93"
            />
            <polygon
              class="cls8-3"
              points="1121.79 277.69 1054.34 299.94 937.22 338.57 921.6 340.01 929.69 335.93 972.81 314.17 1033.21 299.38 1121.79 277.69"
            />
            <polygon
              class="cls8-11"
              points="1121.79 277.69 972.81 314.17 828.41 251.63 861.16 237.28 884.66 247.85 897.25 231.23 945.95 261.45 1121.79 277.69"
            />
            <line
              class="cls8-17"
              x1="514.02"
              y1="442.79"
              x2="510.02"
              y2="444.18"
            />
            <polygon
              class="cls8-18"
              points="0 177.93 0 221.36 779 197.19 0 177.93"
            />
            <polygon
              class="cls8-9"
              points="1236 258.43 1236 298.84 1231.61 298.96 1218 261.46 1236 258.43"
            />
            <polygon
              class="cls8-2"
              points="1245.2 304.96 1236 304.72 1236 311.06 1232.17 309.94 1231.9 304.61 1231.61 298.96 1236 300.9 1245.2 304.96"
            />
            <polygon
              class="cls8-4"
              points="1231.61 298.96 1197.79 299.89 1218 261.46 1231.61 298.96"
            />
            <polygon
              class="cls8-5"
              points="1218 261.46 1197.79 299.89 1197.79 299.89 1121.79 277.69 1218 261.46"
            />
            <path
              class="cls8-2"
              d="M553.26,367.83l-.55,1.77-1.92,6.1c-13.72,1.77-23.34,6.67-30.08,12.72A45.51,45.51,0,0,0,510,402.89a52.32,52.32,0,0,0-5.06,21V424a47,47,0,0,0,5.14,20.13l-13.21,4.6-11.34-.49-6.29-.27.32,17.89-5.76,1H485.5v.76c-5.33,0-9.65,4.74-9.65,10.57a11.18,11.18,0,0,0,1.74,6l-5.94,4.53H444.76l-8.4,3.07-63,23h-25.1V524l-49.53,18.1L260.89,556l-29.32,10.71-67.82,24.79c82.66-175.41,32.51-162.34,32.51-162.34H0V311.06l161.42-28,76.77,77.12,3.76-1.7H242l127.61-19.64,71,19.59,32.64,9.18.27-.11h.15l77.9-31.89,1,16.76a8.6,8.6,0,0,0-4.78-.86c-9,1.17-8.88,9.35-8.88,9.35C539.26,369.07,548.5,371.26,553.26,367.83Z"
            />
            <path
              class="cls8-2"
              d="M1236.13,591.54l-26.44-8.87-83.15-27.87-30.11-10.09-46.3-15.53-8.4-2.81-80.61-27-7.57-2.53-42.82-14.36L899,478.54l-24.35-8.16-13.43-4.51-18.48-6.26-20.15-6.76-24.36-8.16-10.93-3.63-25.18-8.45V381.55h25.18l10.91,1.73V360.4h24.36l10.37,2.6,9.78-2.54v-9.12h18.5l4.62,3.13h0l8.8-2.28v-16h.32l24,1.94L921.61,340l15.7-1.36L1054.46,300l16.4.43,19.86.52h0l121.59,3.2,20,5.85,3.83,1.12v-6.34l9.21.24L1367,358.76l-.82,89.3-133-5S1197.12,440.07,1236.13,591.54Z"
            />
            <polygon
              class="cls8-18"
              points="1366.82 284.84 1366.82 324.17 1245.2 320.96 1236 320.72 1231.9 320.61 1212.15 320.09 1090.6 316.89 1090.59 316.89 1070.73 316.37 1054.34 315.94 1033.21 315.38 932.53 312.72 856.68 310.72 637 304.93 790.84 300.7 897.95 297.75 1085.36 292.59 1104.25 292.07 1116.27 291.74 1128.22 291.41 1136.71 291.18 1140.16 291.08 1171.69 290.21 1211.88 289.11 1222.12 288.82 1236 288.44 1328.5 285.9 1328.51 285.9 1362.42 284.96 1366.82 284.84"
            />
            <polygon
              class="cls8-12"
              points="713.25 394.01 746.24 425.25 536.32 419.53 466.54 475.29 544.27 337.97 585.79 320.6 731.57 389.26 713.25 394.01"
            />
            <path
              class="cls8-17"
              d="M210.9,572.77a3.46,3.46,0,1,0,3.4,3.52h0v-.11a3.49,3.49,0,0,0-3.29-3.51A.11.11,0,0,1,210.9,572.77Z"
            />
            <polygon
              class="cls8-17"
              points="194.11 577.49 197.62 577.49 195.86 572.99 194.11 577.49"
            />
            <path
              class="cls8-17"
              d="M186.09,572.77a3.46,3.46,0,1,0,3.4,3.52h0a3.49,3.49,0,0,0-3.29-3.51Z"
            />
            <path
              class="cls8-19"
              d="M556.89,399.24l.92,70L723,441.71l11.29-1.89s.57-29,1.28-65.34Zm160.92,13.44-69.92,10.21V412.68l69.92-13.44Z"
            />
            <path
              class="cls8-20"
              d="M556.89,399.24l.92,70-7.43,1.24-5.87-229.59a9,9,0,0,1,2.55-2.63,3.87,3.87,0,0,1,2-.63c2.61,0,184.58-11.74,184.58-11.74s3-1.28,4.17,3.25a17.83,17.83,0,0,1,.42,4.25L554.89,246.14Z"
            />
            <polygon
              class="cls8-21"
              points="575.82 263.75 575.82 374.5 571.25 384.72 571.25 258.53 575.82 263.75"
            />
            <polygon
              class="cls8-21"
              points="724.53 246.14 712.14 252.66 575.82 263.75 571.25 258.53 724.53 246.14"
            />
            <polygon
              class="cls8-21"
              points="724.53 246.14 721.92 362.89 709.53 355.72 712.14 252.66 724.53 246.14"
            />
            <polygon
              class="cls8-21"
              points="721.92 362.89 571.25 384.72 575.82 374.5 709.53 355.72 721.92 362.89"
            />
            <polygon
              class="cls8-22"
              points="717.82 399.24 717.82 412.68 647.89 422.89 647.89 412.68 717.82 399.24"
            />
            <path
              class="cls8-23"
              d="M550.38,470.51,538,466.61v31a133.92,133.92,0,0,0-16.4,2.86l-51.43-40.4-8.83-213.93,6.22-32,79.51,24.11a9,9,0,0,0-2.55,2.63Z"
            />
            <path class="cls8-24" d="M822.36,466.27l-.51-.26" />
            <path
              class="cls8-25"
              d="M802.36,478.74l10.9,6.12-152,32-49.57,8.48-11.09-7.82-21.52,4.56L544.51,495l140.18-27.08,69.57-13.49,31.57-6.1s17.41,8.39,36,17.68l-9.31,1.33h0l-24.8,3.17Z"
            />
            <polygon
              class="cls8-22"
              points="868.02 500.33 868.02 510.94 571.25 578.29 510.6 525.6 510.6 514.74 571.91 565.08 710.18 534.42 868.02 500.33"
            />
            <line
              class="cls8-24"
              x1="813.23"
              y1="467.25"
              x2="812.54"
              y2="467.34"
            />
            <path
              class="cls8-21"
              d="M737.83,229.17c-1.15-4.53-4.17-3.25-4.17-3.25s-182,11.74-184.58,11.74a3.87,3.87,0,0,0-2,.63l-79.51-24.11,39.13-2,22.52,5.55,86.4-4.25-7.82-5.87,39.13-2s73.7,17.61,83.49,18.27h.5C738,224.61,738.13,227.76,737.83,229.17Z"
            />
            <path class="cls8-24" d="M737.83,229.17a3.41,3.41,0,0,1-.23.68" />
            <path
              class="cls8-26"
              d="M868,500.33,710.18,534.42,571.91,565.08,510.6,514.74v-9.67l61.31,51.54,295.75-65h0C872.07,496.91,868,500.33,868,500.33Z"
            />
            <polygon
              class="cls8-26"
              points="822.36 465.94 822.36 475.77 802.36 478.74 787.72 470.51 812.53 467.34 812.54 467.34 821.85 466.01 822.36 465.94"
            />
            <polygon
              class="cls8-26"
              points="813.23 484.86 813.23 491.64 611.69 533.77 601.26 526.6 580.39 531.16 543.21 501.81 543.21 494.05 544.51 494.95 579.08 522.03 600.61 517.47 611.69 525.29 661.26 516.81 813.23 484.86"
            />
            <path
              class="cls8-23"
              d="M557.81,469.27l-7.43,1.24L538,466.61v31h5.27V494l1.3.9,140.18-27.08s-5.17-3.89,11.14-6.5c8.32-1.33,18.51-2.15,27.19-3.07V441.74Zm150.45-11.16-9.78,2-17.61,3.63v-8.91l27.39-5.22Z"
            />
            <path
              class="cls8-21"
              d="M867.66,491.61l-295.75,65L510.6,505.08c2.16-1.92,6.44-3.43,11-4.57a133.92,133.92,0,0,1,16.4-2.86h5.26v4.17l37.17,29.35,20.83-4.56,10.43,7.17,201.57-42.17v-6.78l-10.87-6.12,20-3v-9.5c19,9.5,39.11,19.87,43,23.15A17.87,17.87,0,0,1,867.66,491.61Z"
            />
            <line
              class="cls8-24"
              x1="723.02"
              y1="458.97"
              x2="723.02"
              y2="458.3"
            />
            <path
              class="cls8-26"
              d="M858.26,443.76l-5.87,1.31-1.3,2a119.28,119.28,0,0,0-52.18-9.79c-29.35,1.31-38.34,9.59-44.61,17.19l-69.58,13.44s-5.17-3.89,11.14-6.5c8.32-1.33,18.51-2.15,27.19-3.07,8.32-.88,15.26-1.85,17.81-3.45,5.22-3.26,7.83-2,15-11.74s38.48-12.39,50.22-11.74S840.62,433.33,858.26,443.76Z"
            />
            <polygon
              class="cls8-22"
              points="955.42 473.77 954.76 489.42 909.11 499.86 859.49 476.87 860.84 454.2 908.46 484.2 955.42 473.77"
            />
            <polygon
              class="cls8-27"
              points="860.84 454.2 859.49 476.87 847.15 471.16 848.45 451.59 860.84 454.2"
            />
            <polygon
              class="cls8-26"
              points="864.76 446.37 860.84 454.2 848.45 451.59 851.06 447.03 852.36 445.07 864.76 446.37"
            />
            <polygon
              class="cls8-26"
              points="955.42 473.77 908.46 484.2 860.84 454.2 864.76 446.37 909.76 477.68 949.84 469.91 955.42 473.77"
            />
            <polygon
              class="cls8-28"
              points="949.84 469.91 909.76 477.68 864.76 446.37 884.32 442.53 904.54 438.55 949.84 469.91"
            />
            <polygon
              class="cls8-28"
              points="884.32 442.53 864.76 446.37 852.36 445.07 858.23 443.76 869.72 441.42 884.32 442.53"
            />
            <polygon
              class="cls8-28"
              points="904.54 438.55 884.32 442.53 869.72 441.42 890.19 437.24 904.54 438.55"
            />
            <line
              class="cls8-24"
              x1="510.6"
              y1="514.74"
              x2="509.95"
              y2="514.21"
            />
            <polygon
              class="cls8-26"
              points="698.44 453.55 698.44 460.13 680.83 463.76 680.83 456.67 698.44 453.55"
            />
            <polygon
              class="cls8-22"
              points="708.22 449.58 708.22 458.11 698.44 460.13 698.44 453.55 680.83 456.67 680.83 454.85 708.22 449.58"
            />
            <path
              class="cls8-29"
              d="M554.89,246.14l2,153.1,178.7-24.73c1.15-58.27,2.65-135.26,2.66-141.09Zm167,116.75L571.26,384.72V258.53l153.28-12.39Z"
            />
            <polygon
              class="cls8-25"
              points="712.14 252.66 709.53 355.72 575.82 374.5 575.82 263.75 712.14 252.66"
            />
            <line
              class="cls8-24"
              x1="735.6"
              y1="374.51"
              x2="735.6"
              y2="374.51"
            />
            <path
              class="cls8-30"
              d="M884.05,208.61c0-.14-.13-.68-.21-1.46h0C884,208,884,208.49,884.05,208.61Z"
            />
            <path class="cls8-30" d="M883.85,207.18V207" />
            <path class="cls8-30" d="M884.05,208.61h0" />
            <polygon
              class="cls8-30"
              points="850.8 205.32 850.8 205.41 850.71 205.41 850.8 205.32"
            />
            <line
              class="cls8-24"
              x1="544.51"
              y1="497.64"
              x2="543.21"
              y2="497.64"
            />
            <polygon
              class="cls8-31"
              points="478.96 396.48 478.96 384.72 529.8 411.07 529.8 421.89 478.96 396.48"
            />
            <polygon
              class="cls8-32"
              points="478.96 414.74 478.96 402.98 529.8 429.33 529.8 440.15 478.96 414.74"
            />
            <polygon
              class="cls8-33"
              points="478.96 433 478.96 421.24 529.8 447.59 529.8 458.41 478.96 433"
            />
            <polygon
              class="cls8-34"
              points="478.96 451.27 478.96 439.51 529.8 465.85 529.8 476.67 478.96 451.27"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default Fcomputacion;
